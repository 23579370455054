.login-modal,
.register-modal {
  max-height: 90vh;
  overflow: auto;
}
.form-container {
  position: relative;
  top: 10px;
  width: 380px;
  border-radius: 10px;
  background-color: var(--accent-2);
  padding: 2rem;
  color: rgba(243, 244, 246, 1);
  overflow: auto;
}

.title {
  text-align: center;
  font-size: 35px;
  line-height: 2rem;
  margin-bottom: 10px;
}

.form {
  margin-top: 1.5rem;
}

.input-group {
  margin-top: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.input-group label,
.input_container label {
  display: block;
  color: var(--text-primary);
  margin-bottom: 4px;
}
.title_container .subtitle {
  font-size: 11px;
  text-align: center;
  color: var(--text-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0 10px 0;
}
.input-group input {
  width: 100%;
  border-radius: 0.375rem;
  border: 1px solid rgba(55, 65, 81, 1);
  outline: 0;
  background-color: var(--accent-2);
  padding: 0.75rem 1rem;
  color: rgba(243, 244, 246, 1);
}

.input-group input:focus {
  border-color: var(--brand);
}

.forgot {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  margin: 15px 0 17px 0;
}

.forgot,
.signup div {
  color: rgba(255, 255, 255, 0.81);
  text-decoration: none;
  font-size: 14px;
  display: inline-block;
  width: auto;
  transition: all 0.3 ease;
}

.forgot:hover,
.signup div:hover {
  color: #ffffff;
}

.sign {
  display: block;
  width: 100%;
  background-color: var(--brand);
  padding: 8px;
  text-align: center;
  color: #fff;
  border: none;
  font-size: 13px;
  border-radius: 0.375rem;
}


.social-icons {
  display: flex;
  justify-content: center;
}
.social-icons svg {
  margin: 10px 0;
  cursor: pointer;
  font-size: 23px;
}

.signup {
  text-align: center;
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--text-primary);
}

.input_container {
  width: 100%;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.icon {
  width: 20px;
  top: 40px;
  font-size: 20px;
  position: absolute;
  z-index: 99;
  color: #000;
  left: 12px;
  bottom: 8.5px;
}

.input_field {
  width: auto;
  height: 33px;
  font-size: 13px;
  padding: 0 0 0 40px;
  border-radius: 7px;
  outline: none;
  color: #000;
  border: 1px solid #ffffffa9;
  filter: drop-shadow(0px 1px 0px #efefef)
    drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
  background: #ffffffa9;
}

.input_field:focus {
  color: #000;
}
