.promoted-small-offer-container{
    /* padding-top: 10px; */
}
.promoted-small-offer-container-title{
    font-size: 20px;
    color: var(--text-white);
    font-family: "Inter Tight", sans-serif;
    font-weight: 400;
    margin-bottom: 17px;
}
.promoted-small-offer-item{
    position: relative;
    height: 192px;
    width: auto;
    min-width: 130px;
    background-color: var(--bg-color-3);
    border-radius: var(--default-border-radius);
    padding: 10px;
    cursor: pointer;
}

.promoted-small-offer-item:hover{
    transition: ease 0.3s;
    background-color: var(--bg-color-4);
}



.promoted-small-offer-item-image img, .promoted-small-offer-item-image {
    width: 100%;
    border-radius: var(--default-border-radius);
}
.promoted-small-offer-item-image {
    position: relative;
    overflow: hidden;
    height: 110px;
    margin-bottom: 5px;
    border-radius: var(--default-border-radius);
    background-color: rgb(255, 253, 253);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--default-border-radius);
}
.promoted-small-offer-item-image::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--default-border-radius);
}
.promoted-small-offer-item:hover .promoted-small-offer-item-image::after{
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    transition: ease 0.3s;
}
.promoted-small-offer-item-info-title{
    font-size: 14px;
    color: var(--text-white-3);
    margin-top: 5px;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}

.promoted-small-offer-item-info-description{
    font-size: 9px;
    color: var(--text-gray-4);
    font-family: "Lato", sans-serif;
    font-weight: 300;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}

.promoted-small-offer-item-bottom{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 3px;
}

.promoted-small-offer-item-reward{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 3px;
    gap: 3px;
}
.promoted-small-offer-item-reward-image{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.promoted-small-offer-item-reward-image img{
    width: 9px;
    height: 9px;
}

.promoted-small-offer-item-reward-amount{
    font-size: 13px;
    color: var(--text-white-2);
    font-family: "Inter Tight", sans-serif;
    font-weight: 400;
}

.promoted-small-offer-item-category{
    font-size: 10px;
    color: var(--text-gray-4);
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    background: var(--bg-color-6);
    color: var(--text-white-3);
    padding: 2px 5px ;
    min-width: 40px;
    text-align: center;
    border-radius: var(--default-border-radius);
}

.category-deposit{
    background: #555486;
}

.category-game{
    background: #437276;
}

.category-app{
    background: #47815f;
}

.category-casino{
    background: #a14e4e;
}

.category-register{
    background: #8d4e8d;
}

.category-purchase, .category-paid, .category-pay{
    background: #4e678d;
}

.category-quiz{
    background: #6c8d4e;
}

.category-trial{
    background: #8d3c3c;
}

.category-survey{
    background: #432ad3;
}


.promoted-small-offer-item-devices{
    top: 10px;
    right: 10px;
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 6px;
    padding: 3px;
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border-radius: 0 var(--default-border-radius) 0 var(--default-border-radius)  ;
    /* background-color: #2b343f57; */
}
.promoted-small-offer-item-devices svg{
    width: 17px;
    height: 17px;
    background-color: #47474762;
    border-radius: 50%;
    padding: 3px;
    font-size: 20px;
    fill: var(--text-white);
}

.promoted-small-offer-item-open{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 70%;
    /* background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%); */
    border-radius: var(--default-border-radius);
    display: flex;
    opacity: 0;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: var(--text-white-2);
    fill:var(--text-white);
    transition: ease 0.3s;

  
}
.promoted-small-offer-item-open svg{
    background-color: var(--text-brand);
    fill: var(--text-white);
    border-radius: 50%;
    width: 35px;
    height: 35px;
    padding: 8px;
}
.promoted-small-offer-item:hover .promoted-small-offer-item-open{
    opacity: 1;
    transition: ease 0.3s;
}
.promoted-small-offer-item-locked::after{
    position: absolute;
   background-color: #0000009f;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--default-border-radius);
    display: flex;
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
    z-index: 1;
    opacity: 0.5;
    transition: ease 0.3s;
 
}
.promoted-small-offer-item-locked{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--default-border-radius);
    display: flex;
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
    z-index: 3;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: var(--text-white);
    fill:var(--text-white);
    transition: ease 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.promoted-small-offer-item-locked-text{
    text-align: center;
    font-size: 20px;
    z-index: 2;
    color: var(--text-white-3);
    font-family: "Poppins", sans-serif;
    padding: 0px 10px;
    font-weight: 400;
    font-size: 12px;
}

.promoted-small-offer-item-locked-question{
    font-size: 20px;
    padding: 3px 5px;
    z-index: 2;

    color: var(--text-brand);
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 12px;
}

