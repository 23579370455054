.ptc-ad-principal {
    width: calc(100% - 10px);
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    gap: 20px;
    margin: 0px 5px;
    flex-wrap: wrap;
    justify-content: center;
  }
  @media screen and (max-width: 1015px) {
    .ptc-ad-principal {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .ptc-ad {
      background: #1b242d;
      position: relative;
      width: auto;
      min-width: 200px;
      padding: 15px;
      margin: 5px 0px;
      border-radius: var(--default-border-radius);
      flex-wrap: wrap;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      gap: 20px;
    }
  }
  @media screen and (max-width: 560px) {
    .ptc-ad-principal {
      margin-top: 30px;
      display: grid;
      grid-template-columns: 1fr;
    }
    .ptc-ad {
      background: #1b242d;
      position: relative;
      width: auto;
      min-width: 100%;
      padding: 15px;
      margin: 5px 0px;
      border-radius: var(--default-border-radius);
      flex-wrap: wrap;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      gap: 20px;
    }
    .ptc-ad-description {
      color: rgb(147, 172, 211);
  
      font-size: 13px;
      min-height: auto;
      text-align: center;
    }
  }

  .ptc-ad {
    background: var(--bg-color-3);
    position: relative;
    width: auto;
    padding: 15px;
    margin: 5px 0px;
    border-radius: var(--default-border-radius);
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }
  
  .ptc-ad-title {
    width: 100% !important;
    color: var(--text-white);
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0px;
    text-align: center;
  }
  
  .ptc-ad-description {
    color: var(--text-gray-4);
    font-size: 13px;
    min-height: 30px;
    text-align: center;
    word-wrap: break-word;
  }
  .ptc-ad-details {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .mobile-social-links-ad-fa {
    position: absolute;
    top: 3px;
    left: 3px;
  }
  .featured-ad {
    position: absolute;
    color: #fff;
    font: 10px sans-serif;
    top: 10px;
    right: 10px;
  }
  .ptc-view-top {
    height: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .ptc-view-iframe {
    width: 100%;
    height: calc(100vh - 70px);
    overflow: auto;
  }
  .ptc-view-desktop-info {
    display: inline-block;
  }
  .ptc-view-mobile-info-top {
    display: none;
  }
  .ptc-view-mobile-info-bottom {
    display: none;
  }
  @media screen and (max-width: 919px) {
    .ptc-view-desktop-info {
      display: none;
    }
    .ptc-view-mobile-info-top {
      display: inline-block;
    }
    .ptc-view-mobile-info-bottom {
      display: none;
    }
  }
  .ptc-view-logo-desktop {
    display: inline-block;
  }
  .ptc-view-logo-mobile {
    display: none;
  }
  @media screen and (max-width: 680px) {
    .ptc-view-mobile-info-top {
      display: none;
    }
    .ptc-view-mobile-info-bottom {
      min-height: 20px;
      display: block;
      background: #6e212180;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ptc-view-iframe {
      width: 100%;
      height: calc(100vh - 90px);
      overflow: auto;
    }
    .ptc-view-logo-desktop {
      display: none;
    }
    .ptc-view-logo-mobile {
      display: inline-block;
    }
  }
  

  .ptc-ad-btn {
    display: inline-block;
    padding: 5px 20px;
    border-radius: var(--default-border-radius);
    margin: 0 auto;
    cursor: pointer;
  }

  .ptc-ad-reward{
        color: rgb(152, 218, 239);
        font-size: 13px;
        margin: 5px 0px 0px;
        background: rgb(21, 123, 155);
        border-radius: var(--default-border-radius);
        padding: 0px 10px;
        min-width: 80px;
        text-align: center;
  }

  .ptc-ad-timer{
    color: rgb(21, 123, 155);
    font-size: 13px;
    margin: 5px 0px 0px;
    background: rgb(152, 218, 239);
    border-radius: var(--default-border-radius);
    padding: 0px 10px;
    min-width: 80px;
    text-align: center;
}

.ptc-ad-promoted{
    filter:  drop-shadow(0px 0px 5px var(--text-brand));
    backdrop-filter: blur(5px);
}

.watch-ptc-tmrw{
    display: block;
    margin-bottom: 7px;
    margin-top: -21px;
    color: var(--text-gray-4);
    font-size: 10px;
    text-align: center;
}
.payout-bottom-left {
  text-align: right;
  font-size: 13px;
  color: rgb(149, 150, 152);
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: auto;
}
.payout-fee {
  width: 250px;
  display: flex;
  justify-content: space-between;
  padding: 5px 7px;
  font-size: 14px;
}
.payout-price {
  width: 250px;
  background-color: #2a3546;
  color: #fff;
  font-weight: 550;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
  font-size: 14px;
}
.payout-button {
  width: 180px;
  background-color: var(--text-brand-2);
  color: rgb(243, 243, 243);
  text-align: center;
  padding: 8px 0px;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
}

.add-balance-ptc-banner {
  width: 48%;
  padding: 20px;
  background: var(--bg-color-3);
  border-radius: 5px;
}

@media screen and (max-width: 600px) {
  .add-balance-ptc-banner {
    margin-top: 15px;
    width: 100%;
  }
}