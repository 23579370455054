.footer-main{
    margin-top: auto;
    width: 100%;
    padding:20px 40px;
    background-color: var(--bg-color-5);

}
.footer-bottom{
    border-top: 1px solid var(--bg-color-4);
    display: flex;
    flex-direction: row;
    color: var(--text-gray-4);
    font-family: "Poppins", sans-serif;
    font-optical-sizing: auto;
    font-size: 10px;
    justify-content: space-between;
    padding-top: 20px;
}
.footer-top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
}
.footer-top-second-items{
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 5px;
    margin: 20px 0;
}
.footer-top-second-item a{
    color: var(--text-white-2);
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    font-optical-sizing: auto;
    font-size: 12px;
    margin-first: 10px;
}
.footer-top-social-links{
    display: flex;
    margin: 0 auto;
    justify-content: end;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    gap: 20px;
    color: var(--text-white-2);
    margin-bottom: 20px;
}
.footer-top-social-links a{
    color: var(--text-white-2);
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
}
.footer-top-social-links a:hover{
    transition: 0.3s ease;
    color: var(--text-brand-2);
}
.footer-top-social-links a:nth-child(2){
    font-size: 22px;
}
.footer-top-social-links a:nth-child(3){
    margin-top: -2px;
    font-size: 26px;
}
.footer-top-first{
    width: 350px;
    gap: 20px;
    display: flex;
    flex-direction: column;
}
.footer-top-first-title{
    color: var(--text-white);
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 0px;
    text-align: start;
    font-family: 'Poppins', sans-serif;
}

.footer-top-first-description{
    color: var(--text-gray-4);
    font-size: 14px;
    min-height: 30px;
    text-align: start;
    word-wrap: break-word;
    font-family: 'Poppins', sans-serif;
}

.footer-top-first-link img{
    height: 40px;
 }

 .footer-top-second .header-logo-mobile{
    margin-left: auto;
 }