.side-menu-container{
    box-sizing: border-box;
    transition: ease 0.3s;
    position: fixed;
    background: var(--bg-color-5);
    width: 55px;
    height: calc(100vh - 81.5px);
    margin-top: 81.5px;
    display: flex;
    z-index: 4;
    flex-direction: column;
    padding: 11px 0px 11px 0px;
    overflow-y: scroll;
}

.side-menu-container::-webkit-scrollbar {
    width: 1px;
    height: 4px;
  }
  
  /* Track */
  
  .side-menu-container::-webkit-scrollbar-track {
    background: var(--bg-color-2);
  }
  
  /* Handle */
  
  .side-menu-container::-webkit-scrollbar-thumb {
    background: var(--bg-color-6);
  }
  
  /* Handle on hover */
  
  .side-menu-container::-webkit-scrollbar-thumb:hover {
    background: var(--bg-color-6);
  }

.side-menu-category{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.side-menu-category-line{
    height: 2px !important;
    width: 100%;
    background-color: var(--bg-color-6) ;
}

.side-menu-category-title{
    display: none;

    font-size: 12px;
    color: var(--text-gray-3);
    margin: 0;
    margin-bottom: 3px;
    margin-right: 5px;
    font-family: "Lato", sans-serif;
    font-weight: 700;
}

.side-menu-button, .side-menu-button-x{
    text-decoration: none;
    display: flex;
    padding: 10px 0px 10px 0px;
    cursor: pointer;
    border-radius: var(--default-border-radius);
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin: 2px 10px 0px 10px;
    height: 42px;
    min-height: 42px;
    padding: 0px 10px 0px 10px;
}

.side-menu-button-icon,.side-menu-button-x-icon{
    margin-bottom: 4px;
    font-size: 15px;
    color: var(--text-gray-2);
}

.side-menu-button-title, .side-menu-button-x-title{
    display: none;
    color: var(--text-gray-2);
    font-family: "Inter Tight", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-size: 15px;
    font-style: normal;
    white-space: nowrap;       /* Prevents line breaks */
    overflow: hidden;          /* Hides overflowing content */
    text-overflow: ellipsis;   /* Adds "..." if the text overflows */
    display: inline-block;
}

.side-menu-button:hover, .side-menu-button-x:hover{
    background: var(--bg-color-3);
    transition: ease 0.3s;
    cursor: pointer;
}

.side-menu-button:hover .side-menu-button-title, .side-menu-button-x:hover .side-menu-button-x-title{
    color: var(--text-gray);
    transition: ease 0.3s;
}

.side-menu-button:hover .side-menu-button-icon, .side-menu-button-x:hover .side-menu-button-x-icon{
    color: var(--text-gray);
    transition: ease 0.3s;
}

.side-menu-button-selected, .side-menu-button-x-selected{
    background: var(--bg-color-3);
}

.side-menu-button-selected .side-menu-button-title, .side-menu-button-x-selected .side-menu-button-x-title{
    color: var(--text-gray);
}

.side-menu-button-selected .side-menu-button-icon, .side-menu-button-x-selected .side-menu-button-x-icon{
    color: var(--text-gray);
}

.side-menu-container-open{
    transition: ease 0.3s;
    width: 223px;
    position: fixed;
    background: var(--bg-color-5);
    width: 223px;
    padding: 11px 0px 11px 10px;
}

.side-menu-container-open .side-menu-category-title{
    display: flex;
}
.side-menu-container-open .side-menu-button, .side-menu-container-open .side-menu-button-x{
    height: 42px;
    min-height: 42px;
    margin: 2px 20px 0px 10px;
}

.side-menu-container-open .side-menu-button-title, .side-menu-container-open .side-menu-button-x-title{
    display: flex;
}
.chat-top-online {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  margin-top: 10px;
}
.chat-top-online-dot {
  background: rgb(22, 198, 54);
  /* rgba(24, 163, 50, 0.218); */
  border-radius: 100%;
  border: 3px solid rgb(23, 86, 35);
  width: 13px;
  height: 13px;
}

.chat-top-online p {
  margin-left: 1px;
  font-size: 13px;
  color: var(--text-gray-4);
  margin-top: 0px;
  margin-bottom: 1px;
}
.side-menu-container .chat-top-online{
    display: none;
}
.side-menu-container-open .chat-top-online{
    display: flex;
}
@media screen and (max-width:1050px) {
    .side-menu-container {
        margin-top: 135.5px;
        width: 0px;
        padding-bottom: 100px;
    }
    .side-menu-container .side-menu-category{
        display: flex;
    }
    .side-menu-container .side-menu-button, .side-menu-container .side-menu-button-x{
        height: 42px;
        min-height: 42px;
        margin: 2px 20px 0px 10px;
    }
    
    .side-menu-container .side-menu-button-title, .side-menu-container .side-menu-button-x-title{
        white-space: nowrap;       /* Prevents line breaks */
        overflow: hidden;          /* Hides overflowing content */
        text-overflow: ellipsis;   /* Adds "..." if the text overflows */
        display: inline-block;
    }
    .side-menu-container-open{
        width: 223px;
        display: flex;
        height: calc(100vh - 81.5px - 52px);
    }
}

@media screen and (max-width:600px) {
    .side-menu-container {
        width: 0px;
    }
    .side-menu-container .side-menu-category{
        display: flex;
    }
    .side-menu-container .side-menu-button, .side-menu-container .side-menu-button-x{
        height: 42px;
        min-height: 42px;
        margin: 2px 20px 0px 10px;
    }
    
    .side-menu-container .side-menu-button-title, .side-menu-container .side-menu-button-x-title{
        white-space: nowrap;       /* Prevents line breaks */
  overflow: hidden;          /* Hides overflowing content */
  text-overflow: ellipsis;   /* Adds "..." if the text overflows */
  display: inline-block;
    }
    .side-menu-container-open{
        width: 100%;
        display: flex;
    }
}