.terms-page{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: 20px;
    width: 100%;
}
.terms-item{
    width: calc(100% - 40px);
    margin: 0px 20px;
    border-radius: 10px;
    background: var(--bg-color-3);
    padding: 20px;
    border-radius: var(--default-border-radius);
}

.terms-title{
    font-family: "Lato", sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: var(--text-white);
    margin-bottom: 20px;
}

.terms-content{
    font-family: "Lato", sans-serif;
    font-size: 14px;
    color: var(--text-gray-4);
    text-align: justify;
}
.tos-al a{
    color: var(--text-gray-4);
    font-size: 15px;
    text-decoration: none;
}
.tos-al{
    color: var(--text-gray-4);
    font-size: 15px;
    margin: 5px 0px;

}
.tos-al a:hover,  .tos-al:hover{
    transition: 0.3s ease;
    color: var(--text-brand);
    cursor: pointer;
}

.terms-top-info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    width: 100%;
    margin-bottom: 20px;
    margin-left: 20px;
}

.terms-top-info-title{
    font-family: "Lato", sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: var(--text-white);
    margin-bottom: 10px;
}

.terms-top-info-last-edit{
    font-family: "Lato", sans-serif;
    font-size: 12px;
    color: var(--text-gray-4);
}
.border-policy {
    width: 100%;
}
.border-policy tr {
    width: 100%;
    background-color: transparent !important;
    font-size: 12px;
  }
  .border-policy td {
    padding: 5px 10px;
  }
  .border-policy tr {
    border: 1px solid gray;
    padding: 0px 10px;
  }