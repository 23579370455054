.earnfeed::-webkit-scrollbar {
  width: 10px;
  height: 0px;
}

/* Track */

.earnfeed::-webkit-scrollbar-track {
  background: #111923;
}

/* Handle */

.earnfeed::-webkit-scrollbar-thumb {
  background: #262a31;
}

/* Handle on hover */

.earnfeed::-webkit-scrollbar-thumb:hover {
  background: #262a31;
}
.banner-bar {
  position: relative;
  width: calc(100vw - 235px);
  margin-left: 235px;
  background: #11192367;
  color: var(--text-primary);
  text-align: center;
  font-size: 12px;
  z-index: 1;
  transition: all 0.25s;
}

.earning-feed {
  position: fixed;
  width: calc(100vw - 235px);
  height: 67px;
  top: 60px;
  z-index: 10;
  margin-left: 235px;
  background: var(--accent-0);
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  transition: 0.25s;
  padding: 15px 0;
  padding-left: 5px;
  gap: 10px;
  overflow: auto hidden;
  /* scrollbar-width: thin;
  scrollbar-color: transparent transparent; */
}
.earning-feed.chat-open,
.banner-bar.chat-open {
  width: calc(100vw - 235px - 260px);
}
.earnfeed-element {
  display: flex;
  background: var(--accent-3);
  justify-content: start;
  align-items: center;
  border-radius: 10px;
  width: auto;
  cursor: pointer;
}

.earnfeed-avatar {
  height: 24px;
  width: 24px;
  border-radius: 5px;
  margin-left: 8px;
}
.earnfeed-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.earnfeed-mid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 5px;
  width: 100px;
}

.earnfeed-mid-title {
  color: var(--text-secondary);
  text-transform: uppercase;
  font-size: 8.5px;
  font-weight: bold;
  width: 70px;
}

.earnfeed-mid-desc {
  color: var(--text-primary);
  font-size: 9px;
  max-width: 70px;
  width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.earnfeed-amount {
  height: 25px;
  width: auto;
  padding: 0 7px;
  background: var(--accent-2);
  border-radius: 8px;
  color: #fff;
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
  margin-left: auto;
  width: auto;
  margin-right: 10px;
}

.feed-tooltip {
  font-size: 10px;
  padding: 5px 10px;
  color: var(--text-secondary);
  font-weight: bold;
}

.feed-tooltip div {
  margin-bottom: 0px;
  padding: 3px;
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.tippy-box[data-theme~="personal"] {
  background: var(--accent-1);
  border-radius: 10px;
  opacity: 0.95;
  width: 100%;
}
.tippy-box[data-theme~="personal"][data-placement^="bottom"]
  > .tippy-arrow::before {
  border-bottom-color: var(--accent-5);
}
.primary-feed-tooltip-el {
  display: flex;
  margin-bottom: 0px;
  color: #4eb9cc !important;
  font-weight: 300;
}

@media screen and (max-width: 1200px) {
  .banner-bar {
    width: 100vw;
    margin-left: 0px;
  }
}
