
.header-container{
    position: fixed;
    background: var(--bg-color-1);
    width: 100%;
    padding: 20px  35px 20px 35px;
    display: flex;
    flex-direction: row;
    z-index: 4;
    z-index: 50;
    justify-content: space-between;
}
.header-left, .header-right{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:20px;
}

.header-logo{
    /* filter: brightness(0) invert(0.9); */
}

.header-logo-desktop{
    width: 155px;
    margin-bottom: 3px;
}

.header-logo-mobile{
    display: none;
    width: 24.5px;
    margin-bottom: 3px;
}

.header-burger-menu {
    width: 27px;
    height: 18.5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    margin: 0px 0 0 20px;
  }
  
.header-burger-menu .header-burger-menu-line {
    width: 100%;
    height: 3px;
    background-color: var(--bg-color-gray-1);
    border-radius: 5px;
}

.header-burger-menu:hover .header-burger-menu-line{
    background-color: var(--bg-color-gray-2);
    transition: ease 0.3s;
}

.header-balance{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background: var(--bg-color-3);
    padding: 7px 15px 7px 15px;
    height: 41.5px;
}

.header-balance-icon{
    width: 13px;
    height: 13px;
    margin-right: 6px;
}

.header-balance-text{
    color: var(--text-white);
    margin-bottom: 2px;
    font-size: 15px;
    font-family: "Lato", sans-serif;
    font-style: semibold;
}

.header-notification{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background: var(--bg-color-3);
    height: 41.5px;
    width: 41.5px;
    color:var(--text-gray-2);
}
.header-notification:hover {
    background: var(--bg-color-4);
    color: var(--text-gray);
    transition: ease 0.3s;
    cursor: pointer;
}
.header-notification-alert{
    position: absolute;
    background: var(--bg-color-red-1);
    color: var(--text-white-2);
    border-radius: 50%;
    font-size: 10px;
    padding: 1px 5px 1px 5px;
    top: 0px;
    right: 0px;
}
.header-notification:hover .header-notification-alert{
    background: var(--bg-color-red-2);
    color: var(--text-white);
    transition: ease 0.3s;
}
.header-profile{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    background: var(--bg-color-3);
    height: 41.5px;
    width: 41.5px;
    cursor: pointer;
}


.header-profile-picture{
    width: 41.5px;
    height: 41.5px;
    border-radius: 50%;
}


.header-profile-dropdown{
    position: absolute;
    background: var(--bg-color-3);
    right: 0px;
    bottom: 0px;
    color:var(--text-gray-2);
    border-radius: 50%;
    font-size: 10px;
    height: 15px;
    padding: 0px 2px;
    flex-direction: column;
}

.header-profile:hover .header-profile-dropdown{
    background: var(--bg-color-4);
    color: var(--text-gray);
    transition: ease 0.3s;
}

@media screen and (max-width:1050px) {
    .header-container{
        transition: ease 0.3s;
        padding: 20px  20px 20px 20px;
    }
    .header-logo-desktop{
        transition: ease 0.3s;
        display: none;
    }
    .header-logo-mobile{
        transition: ease 0.3s;
        display: block;
    }
    .header-left, .header-right{
        transition: ease 0.3s;
        gap:15px;
    }
    .header-burger-menu{
        display: none;
        transition: ease 0.3s;
        margin: 0px 0 0 5px;
    }
}

/* Genel stil */
.toggle-switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
  }
  
  /* Giriş stil */
  .toggle-switch .toggle-input {
    display: none;
  }
  
  /* Anahtarın stilinin etrafındaki etiketin stil */
  .toggle-switch .toggle-label {
    position: absolute;
    top: 0;
    width: 41px;
    height: 20px;
    background-color: #2196f3;
    border-radius: 34px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  /* Anahtarın yuvarlak kısmının stil */
  .toggle-switch .toggle-label::before {
    content: "";
    position: absolute;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    top: 2px;
    left: 4px;
    background-color: #fff;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s;
  }
  
  /* Anahtarın etkin hale gelmesindeki stil değişiklikleri */
  .toggle-switch .toggle-input:checked + .toggle-label {
    background-color: #4caf50;
  }
  
  .toggle-switch .toggle-input:checked + .toggle-label::before {
    transform: translateX(16px);
  }
  
  /* Light tema */
  .toggle-switch.light .toggle-label {
    background-color: #bebebe;
  }
  
  .toggle-switch.light .toggle-input:checked + .toggle-label {
    background-color: #9b9b9b;
  }
  
  .toggle-switch.light .toggle-input:checked + .toggle-label::before {
    transform: translateX(6px);
  }
  
  /* Dark tema */
  .toggle-switch.dark .toggle-label {
    background-color: #4b4b4b;
  }
  
  .toggle-switch.dark .toggle-input:checked + .toggle-label {
    background-color: #717171;
  }
  
  .toggle-switch.dark .toggle-input:checked + .toggle-label::before {
    transform: translateX(16px);
  }

  .header-menu-box .header-menu-box-item,
.header-menu-box-withdraw .header-menu-box-item,
.devices-menu-box .header-menu-box-item{
  color: var(--text-white-2);
  padding: 0px 10px;
  height: 20px;
  font-size: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  flex-direction: row;
  gap: 5px;
  transition: color 0.3 ease;
}
.devices-menu-box .header-menu-box-item{
  display:flex;
  align-items: center;
}
.devices-menu-box .header-menu-box-item p{
  margin-bottom: 0px;
}
.devices-menu-box {
  padding: 20px 10px;
  position: absolute;
  background: var(--bg-color-6);
  height: auto;
  width: 166px;
  top: 30px;
  left: 0px;
  overflow: auto;
  border-radius: var(--default-border-radius);
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.header-menu-box-withdraw .header-menu-box-item {
  padding: 10px;
  height: 30px;
}
.header-menu-box-withdraw-gc .header-menu-box-item-gc {
  padding: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-white-2) !important;
  cursor: pointer;
  background-color: var(--accent-3);
  flex-direction: row;
  text-align: center;
  gap: 0px !important;
}
.header-menu-box-withdraw-gc .header-menu-box-item-gc img {
  height: 13px;
  border-radius: 5px;
  margin-right: 3px;
}
.header-menu-box .header-menu-box-item:hover,
.header-menu-box-withdraw .header-menu-box-item:hover,
.devices-menu-box .header-menu-box-item:hover{
  color: var(--text-white-3);
  transition: ease 0.3s;
}
.show-usd {
  border-top: 1px solid var(--accent-1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  margin-top: -10px;
  padding-top: 15px !important;
}
.header-menu-box .header-menu-box-item svg {
  font-size: 15px;
}


.header-menu-box {
  padding: 20px 10px;
  position: absolute;
  background: var(--bg-color-4);
  height: auto;
  width: 190px;
  top: 45px;
  right: 0px;
  overflow: auto;
  border-radius: var(--default-border-radius);
  display: flex;
  flex-direction: column;
}


.header-menu-box-profile-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.header-menu-box-profile-info{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.header-menu-box-profile-info .user-modal-content-header-left-info-name{
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--text-white-2);
    font-size: 13px;
    font-family: "Lato", sans-serif;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 25px;

}
.header-menu-box-profile-info .user-modal-content-header-left-info-name .user-modal-content-header-left-rank-badge img{
    width: 15px;
    margin-right: -5px;
}
.header-menu-box-profile-info-id, .header-menu-box-profile-info-level{
  position: absolute;
  top: 55px;
  display: inline;
  font-size: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: var(--text-gray-4);
  background-color: #7f2b2b;
  padding: 2px 10px;
  border-radius: var(--default-border-radius);
  color:#ea7272;
  text-align: center;
}
.header-menu-box-profile-info-id svg{
    margin-bottom: 1px;
}
.header-menu-box-profile-info-tickets{
    color: var(--text-white-2);
    border-radius: var(--default-border-radius);
    background: var(--bg-color-1);
    padding: 2px 5px;
    font-size: 12px;
    font-family: "Lato", sans-serif;
}
.header-menu-box-profile-info-tickets .ticket-design{
    font-size: 10px;
    margin-bottom: 1px;
}
.header-menu-box-profile-info-det{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;

}

.header-menu-box .side-menu-button-x {
        transition: ease 0.3s;
        margin: 2px 0px 0px 0px;
        height: 30px;
        min-height: 30px;
        border-top: 0px solid var(--bg-color-1);
        border-radius: 0px;
        padding: 10px 10px;
}
.header-menu-box .side-menu-button-x  .side-menu-button-x-icon{
 color: var(--text-gray);
 font-size: 15px;

}
.header-menu-box .side-menu-button-x .side-menu-button-x-title{
 color: var(--text-gray);
 font-family: 'Lato', sans-serif;
}
.header-menu-box .side-menu-button-x:hover .side-menu-button-x-title, .header-menu-box .side-menu-button-x:hover  .side-menu-button-x-icon{
  color: var(--text-white-2);
 }
.header-menu-box .side-menu-button-x:last-child{
  border-top: 1px solid var(--bg-color-6);
}
.device-selector .deviceSelectorBTN{
  width: 100%;
}
.device-selector .deviceSelectorBTN .side-menu-button-x:last-child{
  border-top: none;
  width: 100%;
}
.header-menu-box .side-menu-button-x div {
    font-size: 13px;
}

.header-notification-box{
  position: absolute;
  background: var(--bg-color-4);
  height: auto;
  width: 210px;
  top: 65px;
  right: 95px;
  overflow: auto;
  border-radius: var(--default-border-radius);
  max-height: 150px;
  padding: 5px;
  display: flex;
  gap: 5px;
  flex-direction: column;
  cursor: default;
}

.header-notification-box-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 10px;
    background: var(--bg-color-5);
    border-radius: var(--default-border-radius);
    padding: 7px 10px;
    cursor: pointer;
}
.header-notification-box-item:hover{
    background: var(--bg-color-3);
    transition: ease 0.3s;
}
.notofication-image img{
    width: 35px;
    height: 35px;
    border-radius: var(--default-border-radius);
}
.notification-details{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.notification-title{
    color: var(--text-white-2);
    font-size: 12px;
    font-family: "Lato", sans-serif;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.notification-details{
    color: var(--text-gray-4);
    font-size: 10px;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.no-notification{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px 0;
    color: var(--text-gray-4);
}

.header-notification-box-header{
  display: inline;
    color: var(--text-white-2);
    font-size: 10px;
    font-family: "Lato", sans-serif;
    font-weight: 300;
    width: auto;
    text-align: center;
    cursor: pointer;
    padding: 3px;
    border-radius: var(--default-border-radius);
    background: var(--bg-color-5);

}
.header-notification-box-header:hover{
    color: var(--text-white-3);
    background: var(--bg-color-3);
    transition: ease 0.3s;
}
