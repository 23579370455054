.promo-code-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 20px;
    width: 100%;
}
.promo-code-main{
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    gap: 20px;
    width: calc(100% );
    margin-top: 0px;
}
.promo-code-container-content{
    padding: 20px;
    border-radius: var(--default-border-radius);
    width: 100%;
    background: var(--bg-color-3);
    min-height: 170px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
@media screen and (max-width: 1050px) {
    .promo-code-container{
        flex-direction: column;
    }
    
}

.promo-code-container-content .input-container{
    border-radius: var(--default-border-radius) var(--default-border-radius) 0 0;
}

.promo-code-container-content-button{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  cursor: pointer;
    width: 100%;
    background: var(--text-brand-2);
    padding: 6px 7px 7px 7px;
    border-radius: 0 0 var(--default-border-radius) var(--default-border-radius);
    color: var(--text-white-3);
    font-size: 14px;
    text-transform: uppercase;
    font-family: 'Lato', sans-serif;
    margin-top: 0%;
}
.promo-code-container-content-button:hover{
    transition: 0.3s ease;
    background: var(--text-brand-3);
    color: var(--text-white-2);
}
.promo-code-container-title{
    color: var(--text-white-2 );
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0px;
    text-align: center;
    font-family: 'Latp', sans-serif;
    margin-bottom: 15px;
}
.promo-code-container-description{
    color: var(--text-gray-4);
    font-size: 13px;
    min-height: 30px;
    text-align: center;
    word-wrap: break-word;
    font-family: 'Latp', sans-serif;
}
.promo-code-container-links{
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    gap: 10px;
    color: var(--text-white-2);
}
.promo-code-container-links a{
    color: var(--text-white-2);
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
}
.promo-code-container-links a:hover{
    transition: 0.3s ease;
    color: var(--text-brand-2);
}
.promo-code-container-links a:nth-child(2){
    font-size: 22px;
}
.promo-code-container-links a:nth-child(3){
    font-size: 26px;
}
.promo-code-content-title{
    font-family: 'Latp', sans-serif;
    color: var(--text-white-2);
    font-size: 20px;
    margin-bottom: -1px;
}