.promoted-offer-image-container {
  width: 100%;
  height: auto;
}

.promoted-offer-image-container-1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-clip: border-box;
  background-size: cover;
  aspect-ratio: 1;
  height: 100px;
  border-radius: 10px;
}

promoted-offerwall-image-container {
  width: 100%;
  height: auto;
}
