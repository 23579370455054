

.cashout-item{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px 0;
    width: 130px;
    /* background: var(--bg-color-3); */
    border-radius: var(--default-border-radius);
    height: auto;
    text-decoration: none;
    height: 160px;
    cursor: pointer;
}
.cashout-item:hover{
    border: 1px solid var(--color-brand);
    transition: ease 0.3s;
}
.cashout-item-title{
    position: absolute;
    bottom: 0;
    font-size: 12px;
    color: var(--text-white-2);
    margin: 0;
    margin-right: 5px;
    font-family: "Lato", sans-serif;
    font-weight: 700;
    background: #151414a2;
    padding: 5px 15px;
    border-radius: var(--default-border-radius) var(--default-border-radius) 0 0;
    text-transform: capitalize;
}
.cashout-item-img{
    width:  40%;
    margin: 0;
    /* filter:  brightness(0) invert(0.8); */
    margin-bottom: 5px;
}
.cashout-item:hover .cashout-item-img{
    /* filter:  brightness(0) invert(1); */
    transition: ease 0.3s;
}

.cashout-items{
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: center;
    gap: 20px;
    width: calc(100% - 40px);
    height: auto;
    border-radius: var(--default-border-radius);
}

.cashout-title{
    color: var(--text-white);
    margin-bottom: 10px;
    font-size: 20px;
    font-family: "Lato", sans-serif;
    font-weight: 600;
    text-transform: capitalize;
}
.cashout-description{
    color: var(--text-gray-4);
    font-size: 17px;
    font-family: "Lato", sans-serif;
    font-weight: 500;
    margin-bottom: 20px;
}
.cashout-body, .cashout-header{
    margin: 20px 0;
    margin-top: 0px;
}
.cashout-body{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 20px;
    width: 100%;
}
.cashout-header {
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: 20px;
}

.cashout-header-balance-pend{
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
    /* background: var(--bg-color-4); */
    border-radius: var(--default-border-radius);
}
.cashout-header-right{
    width: 70%;
}

.cashout-header-balance{
    position: relative;
    color: var(--text-white);
    font-size: 18px;
    font-family: "Lato", sans-serif;
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    height: 80px;
    min-width: 170px;
    background: var(--bg-color-4);
    border-radius: var(--default-border-radius);
}

.cashout-header-balance-title{
    color: var(--text-gray-4);
    font-size: 12px;
    font-family: "Lato", sans-serif;
    font-weight: 500;
    margin: 0;
}

.cashout-header-balance-why{
    position: absolute;
    color: var(--text-brand-2);
    font-family: "Lato", sans-serif;
    font-weight: 500;
    cursor: pointer;
    right: 10px;
    top: 13px;
}

.cashout-header-balance-amount{
    display: flex;
    align-items: center;
}

@media screen and (max-width:1050px) {
    .cashout-header{
        flex-direction: column;
        width: 100%;
        gap: 20px;
    }
    .cashout-header-left{
        width: calc(100% - 40px);
    }
    .cashout-header-right{
        width: calc(100% - 40px);
        justify-content: start !important;
    }
    .cashout-header-right{
        flex-direction: column;
     }
     .cashout-header-balance,.cashout-header-balance-pend{
         padding: 10px;
         height: 60px;
         width: 100%;
     }
     .cashout-header-balance-pend{
        padding: 0px;
    }
     .cashout-header-balance-title{
         font-size: 10px;
     }
     .cashout-header-balance-why{
         top: 5px;
         right: 5px;
     }
     .cashout-header-balance-amount{
        font-size: 14px;
    }
    .cashout-items{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
        width: 100%;
    }
    .cashout-item{
        width: 100%;
        min-width: 100px;
        max-width: 250px;
    }
    .cashout-header-balance{
        min-width: 100px;
    }
}

@media screen and (max-width:220px) {
   
    .cashout-header-balance-pend{
        height: 100%;
        margin: 0px;
        padding: 0px;
        flex-direction: column;
    }
}
