.promoted-offer-image-container {
  width: 100%;
  height: auto;
}

.promoted-offer-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-clip: border-box;
  background-size: cover;
  height: 108px;
  aspect-ratio: 1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
