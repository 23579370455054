
.earning-feed-container{
    transition: ease 0.3s;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 8px 10px;
    background: var(--bg-color-7);
    width: 100%;
    position: fixed;
    margin-top: -83px;
    width: calc(100% - 220px);
    overflow-x: scroll;
    z-index: 4;
}

.earning-feed-container::-webkit-scrollbar {
    width: 1px;
    height: 1px;
  }
  
  /* Track */
  
  .earning-feed-container::-webkit-scrollbar-track {
    background: var(--bg-color-2);
  }
  
  /* Handle */
  
  .earning-feed-container::-webkit-scrollbar-thumb {
    background: var(--bg-color-6);
  }
  
  /* Handle on hover */
  
  .earning-feed-container::-webkit-scrollbar-thumb:hover {
    background: var(--bg-color-6);
  }


.earning-feed-container-full{
    transition: ease 0.3s;
    width: calc(100%);
    z-index: 15;
}

.earning-feed-item{
    width: 169px;
    min-width: 169px;
    height: 38px;
    background: var(--bg-color-3);
    border-radius: var(--default-border-radius);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0px 7px;
    gap: 7px;
}


.earning-feed-item-image img{
    width: 25px;
    height: 25px;
    border-radius: var(--default-border-radius);
    background: var(--bg-color-4);
    display: flex;
    justify-content: center;
    align-items: center;
}
.earning-feed-item-content{
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: center;
    gap: 0px;
}
.earning-feed-item-content-title{
    color: var(--text-ef-1);
    margin: 0px;
    font-size: 9px;
    font-family: "Inter Tight", sans-serif;
    font-weight: semibold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 55px;

}
.earning-feed-item-content-description{
    margin: 0px;
    color: var(--text-gray-2); 
    font-size: 8px;
    font-family: "Inter Tight", sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 55px;
}

.earning-feed-item-reward{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bg-color-8);
    border-radius: var(--default-border-radius);
    padding: 5px 10px;
    margin-left:  auto;
    overflow: hidden;
}
.earning-feed-item-reward-image{
    display: flex;
    align-items: center;
    justify-content: center;
}

.earning-feed-item-reward-image-coin{
    width: 7px;
    height: 7px;
}

.earning-feed-item-reward-amount{
    color: var(--text-ef-1);
    font-size: 9px;
    font-family: "Inter Tight", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    margin-left: 5px;
    margin-bottom: 0px;
}

@media screen and (max-width: 1050px){
    .earning-feed-container{
        transition: ease 0s;
        width: 100%;
        margin-left: 0;
    }
    
}