@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
  outline: none;
}
:root {
  --text-primary: #93acd3;
  --text-secondary: #758db2;
  --text-highlight: #d3d7de;
    --bg-color-1: #14181B;
    --bg-color-2: #121518;
    /* --bg-color-3: #1C2025; */
    --bg-color-3: #1b1f23;
    --bg-color-4: #1f2329;
    --bg-color-5: #16191D;
    --bg-color-6: #333940;
    --bg-color-7: #111316;
    --bg-color-8: #262D37;
    --bg-color-gray-1: #757A81;
    --bg-color-gray-2: #8e9296;
    --bg-color-red-1: #A43131;
    --bg-color-red-2: #ab3c3c;

    --text-gray: #9494a5;
    /* --text-gray-2: #5A6068; */
    --text-gray-2:#868686;
    --text-gray-3: #4b5158;
    --text-gray-4:#868686;
    /* --text-gray-4: #606874; */
    --text-gray-5: #8ca0b7;
    --text-white: #ececec;
    --text-white-2: #c5c3c3;
    --text-white-3: #D6D6D6;
    --text-ef-1: #919CAB;
    --text-ef-2: #4F5761;
    --text-brand: #499baa;
    --text-brand-2: #4b6f76;
    --text-brand-3: #3d595e;
    --text-green: #468748;

    --default-border-radius:8px;
    
}
*{
    box-sizing: border-box;
}
body{
    background: var(--bg-color-2);
}

.custom_arrows {
    width: 68px !important;
    top: -29px !important;
    right: 2.5px !important;
    position: absolute !important;
  }
  .custom_arrow {
    background-color: var(--bg-color-3) !important;
    fill: var(--text-gray-2) !important;
    border-color: transparent !important;
    opacity: 1 !important;
    transition: opacity 0.3s ease !important;
    border-radius: var(--default-border-radius) !important;
    font-size: 16.68px !important;
    width: 2rem !important;
    height: 2rem !important;
  }
  
  .custom_arrow:hover {
    transition:  0.3s ease !important;
    background-color: var(--bg-color-4) !important;
    fill: var(--text-gray) !important;
  }
  .custom_arrow_banner {
    background-color: var(--accent-4) !important;
    color: #fff !important;
    border-color: transparent !important;
    padding: 4px !important;
    border-radius: 8px !important;
    font-size: 11px !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
    opacity: 0.6 !important;
    top: auto !important;
    transition: opacity 0.3s ease !important;
    position: relative !important;
  }
  .custom_arrow_banner:hover {
    opacity: 1 !important;
  }
  .custom_arrow_banner_container {
    z-index: 2;
    top: 0;
    position: absolute;
    display: flex;
    align-items: center;
    padding: 8px;
    height: 100%;
    background-color: transparent !important;
  }
  .custom_arrow_banner_container:hover {
    background-color: rgba(0, 0, 0, 0.2) !important;
  }
  .custom_arrow_banner_container_right {
    right: 0;
  }
  .custom_arrow_banner_container_left {
    left: 0;
  }
  .custom_arrows_banner svg {
    height: 0.85rem !important;
    width: 1rem !important;
  }
  .splide__arrow--prev {
    left: -0.15rem !important;
  }
  .splide__arrow--next {
    right: -0.15em !important;
  }
  .custom_arrows_banner svg,
  .custom_arrow svg {
    fill: var(--text-secondary) !important;
  }
  .custom_arrow:hover svg,
  .custom_arrows_banner:hover svg {
    fill: var(--text-primary) !important;
  }
  .custom_arrow:disabled:hover svg,
  .custom_arrows_banner:disabled:hover svg {
    fill: var(--text-secondary) !important;
  }
  .custom_arrow:disabled,
  .custom_arrows_banner:disabled {
    opacity: 0.25 !important;
  }
  

  .app-container{
   width: calc(100% - 223px);
   margin-left: 223px;
   display: flex;
   padding-top: 161.5px;
   flex-direction: column;
   min-height: 100vh;
   transition: ease 0.3s;
   gap: 20px;
  }
  .home-container{
    width: calc(100% );
    display: flex;
    padding-top: 161.5px;
    flex-direction: column;
    min-height: 100vh;
    transition: ease 0.3s;
    gap: 20px;
   }
  .app-content{
    width: calc( 100% - 40px);
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .app-container-full{
    width: calc(100% - 50px);
   transition: ease 0.3s;
   min-height: 100vh;
    margin-left: 50px;
  }

  @media screen and (max-width:1050px) {
    .app-container{
      width: calc(100%);
      margin-left: 0px;
      gap: 20px;
      margin-bottom: 50px;
     }
     .app-container-full{
       width: calc(100% );
       margin-left: 0px;
      margin-bottom: 50px;

     }
  }
  .loading-center{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;  
  }
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 34px;
    height: 34px;
    margin: 8px;
    border: 8px solid var(--text-brand);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--text-brand) transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .walls-iframe{
    width: 100%;
    height: 100%;
  }

  .p-datatable .p-datatable-thead > tr > th {
    background-color: var(--bg-color-3) !important;
    color: var(--text-gray) !important;
    border: 0px !important;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 12px;
   
  }

  .p-datatable .p-datatable-thead > tr > th:first-child{
    border-radius: var(--default-border-radius) 0 0 0  !important;

  }
  .p-datatable .p-datatable-thead > tr > th:last-child{
    border-radius: 0 var(--default-border-radius)  0 0  !important;
    display: flex;
    justify-content: end;
  }
  
  .p-datatable {
    overflow: auto;
    width: 100%;
  }
  .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td,
  .p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
    padding: 15px;
  }
  .p-datatable-table {
    width: 100%;
  }
  

.p-datatable .p-datatable-tbody > tr > td {
  border: 0px !important;
  padding: 5px !important;
}

.p-datatable .p-datatable-tbody > tr > td:last-child {
  text-align: right !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-size: 12px;
  color: var(--text-gray);
  padding-right: 15px !important;
}
.wall-offers .p-datatable .p-datatable-tbody > tr > td {
  padding: 0px !important;
}

 .p-datatable .p-datatable-tbody > tr > td:first-child {
  padding:  10px 5px !important;
}


.p-paginator{
  background: transparent !important;
  color: var(--text-gray-4) !important;
  border: 0px !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-size: 12px;
  border-radius: var(--default-border-radius) !important;
  padding: 5px 10px !important;
  margin: 0px !important;
  transition: box-shadow 0.2s;
}

.p-datatable .p-datatable-tbody > tr {
  background: var(--bg-color-3) !important;
  color: #495057;
}

.p-datatable .p-datatable-tbody > tr{
  border-bottom: 1px solid var(--bg-color-1) !important;
}
.p-datatable .p-datatable-tbody > tr:first-child {
  border-top: 1px solid var(--bg-color-1) !important;
}
.p-datatable .p-datatable-tbody > tr:last-child > td:last-child {
  border: 0px !important;
  border-radius: 0 0 var(--default-border-radius) 0 !important;
}
.p-datatable .p-datatable-tbody > tr:last-child > td:first-child {
  border: 0px !important;
  border-radius: 0 0 0 var(--default-border-radius)  !important;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight{
  background: #3b585e !important;
  color: var(--text-white-2) !important;
  border: 0px !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-size: 12px;
  
  border-radius: var(--default-border-radius) !important;
  padding: 5px 5px !important;
  margin: 0 2px !important;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight:hover{
  transition: ease 0.3s;
  background: #46666d !important;
  color: var(--text-white) !important;

}
.p-paginator .p-paginator-pages .p-paginator-page, .p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last {

  margin:  0 2px !important;
  min-width: auto !important;
  width: 40px !important;
  height: 40px !important;
  font-size: 12px;
  background: var(--bg-color-3) !important;
  border-radius: var(--default-border-radius) !important;

}

.p-paginator .p-paginator-pages .p-paginator-page:hover{
    transition: ease 0.3s;
    background: #46666d !important;
    color: var(--text-white) !important;
    border-radius: var(--default-border-radius) !important;
    padding: 5px 5px !important;
    font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-size: 12px;
}

.p-datatable .p-paginator-bottom {
  margin: 20px 0px !important;
}
.feed-tooltip {
  background-color: var(--bg-color-1);
  font-size: 10px;
  padding: 5px 10px;
  color: var(--text-ef-2);
  border-radius: var(--default-border-radius);
  font-weight: bold;
}

.feed-tooltip div {
  margin-bottom: 0px;
  padding: 3px;
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.p-datatable-emptymessage{
  background: var(--bg-color-3) !important;
  color: var(--text-gray-4) !important;
  text-align: left !important;
  width: 100% !important;
  transition: box-shadow 0.2s;
  display: none !important;

}
.p-datatable-emptymessage td{
  text-align: center !important ;
  border-radius: 0 0  var(--default-border-radius)  var(--default-border-radius)  !important;
}
.button, .btn {
  border-radius: var(--default-border-radius);
  border: none;
  font-size: 10px;
  padding: 5px 10px;
  background: var(--bg-color-1);
  color: var(--text-gray-4);
}

.button-disabled{
  background: var(--bg-color-6);
}
.button-disabled:hover{
  background: var(--bg-color-6);
}
.button-brand, .button-brand a{
  background: var(--text-brand-2) !important;
  color: var(--text-white-2) !important;
}
.button-brand:hover, .button-brand a:hover{
  transition: ease 0.3s !important;
  color: var(--text-white-3) !important;
}
.button-red, .button-red a{
  background: #9b454587 !important;
  color: var(--text-white-2) !important;
}
.button-red:hover, .button-red a:hover{
  transition: ease 0.3s !important;
  color: var(--text-white-3) !important;
}
*::-webkit-scrollbar {
  width: 1px;
  height: 6px;
}

/* Track */

*::-webkit-scrollbar-track {
  background: var(--bg-color-2);
}

/* Handle */

*::-webkit-scrollbar-thumb {
  background: var(--bg-color-6);
}

/* Handle on hover */

*::-webkit-scrollbar-thumb:hover {
  background: var(--bg-color-6);
}



.cashout-header-right{
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.input-container{
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
  /* background: var(--bg-color-3); */
  border-radius: var(--default-border-radius);
  padding: 10px 13px;
  width: 100%;
  height: auto;
  transition: ease 0.3s;
  background-color: var(--bg-color-7);
  /* border: 1px solid var(--bg-color-7); */
}

.input-icon{
  color: var(--text-gray-4);
  font-size: 18px;
}
.input-container input, .input-container select{
  width: 100%;
  background: transparent;
  border: 0px;
  margin-top: 2px;
  color: var(--text-white-2);
  font-weight: 600;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
}
.input-container input:focus, .input-container input:hover{
  outline: none;
  border: none;
}

.cat-earn-mob {
  width: 100%;
  gap: 10px;
  overflow: auto;
  display: flex;
}

.cat-earn-mob a {
  text-decoration: none;
  min-width: 100px;
  background: var(--bg-color-3);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  color: var(--text-white-2);
  padding: 5px 10px;
  text-transform: capitalize;
}
.cat-earn-mob a:hover {
  background: var(--text-brand-3);
  color: var(--text-white);
  transition: ease 0.3s;
  cursor: pointer;
}
.cat-earn-mob a.sel {
  background: var(--text-brand-3);
}
.cat-earn-mob a.sel:hover {
  background: var(--text-brand-3);
}
.cat-earn-mob-item.sel a {
  background: var(--text-brand-3);
}
.cat-earn-mob-item.sel a:hover {
  background: var(--text-brand-3);
}

.main-container{
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 0px;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.faq-item {
  position: relative;
  z-index: 2;
  width: 100%;
}
.faq-item-top {
  background: var(--bg-color-3);
  padding: 15px 20px;
  width: 100%;
  border-radius: 7px;
  color: var(--text-white-2);
  cursor: pointer;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: start;
}
.faq-item-top:hover {
  background: var(--bg-color-4);
  transition: ease 0.3s;
}

.faq-item-top i {
  font-size: 12px;
  margin-top: 2px;
}
.faq-item-bottom {
  transition: padding 0.3s ease;
  font-family: 'Lato', sans-serif;
  max-height: 0px;
  background: var(--bg-color-5);
  color: var(--text-gray-4);
  font-size: 14px;
  padding: 0px 20px;
  width: 100%;

  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  overflow: hidden;
}


.faq-item-show {
  max-height: 10000px;
  padding: 15px 20px;
}
.faq-item-active {
  background: var(--bg-color-4) !important;

  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.faq-item-top .fa-solid {
  background: rgba(167, 255, 255, 0.1);
  color: #11b8c7;
  padding: 4px 7px;
  border-radius: 4px;
  margin-right: 10px;
}
.faq-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}


.header-signup-button{
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  border-radius: var(--default-border-radius);
  background: #4d939a;
  color: var(--text-white);
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  border: 1px solid var(--bg-color-6);
  cursor: pointer;
  transition: ease 0.3s;
}
.header-signup-button:hover{
  background: #5fa9b0;
  transition: ease 0.3s;
}
.header-login-buttons{
  display: flex;
  align-items: center;
  gap: 10px;
  
}

.header-login-button{
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  border-radius: var(--default-border-radius);
  background: var(--bg-color-3);
  color: var(--text-white);
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  border: 1px solid var(--bg-color-6);
  cursor: pointer;
  transition: ease 0.3s;
}

.header-login-button:hover{
  background: var(--bg-color-4);
  transition: ease 0.3s;
}

.google-button{
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  border-radius: var(--default-border-radius);
  background: #fff;
  color: #000;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  border: 1px solid var(--bg-color-6);
  cursor: pointer;
  transition: ease 0.3s;
}
.google-button:hover{
  background: #f1f1f1;
  transition: ease 0.3s;
}



  .checkbox-wrapper-29 {
    --size: 13px;
    --background: transparent;
    font-size: var(--size);
    width: 100%;
    margin-left: 4px;
  }

  .checkbox-wrapper-29 *,
  .checkbox-wrapper-29 *::after,
  .checkbox-wrapper-29 *::before {
    box-sizing: border-box;
  }

  .checkbox-wrapper-29 input[type="checkbox"] {
    visibility: hidden;
    display: none;
  }

  .checkbox-wrapper-29 .checkbox__label {
    width: var(--size);
    color: var(--text-white);
  }

  .checkbox-wrapper-29 .checkbox__label:before {
    content: ' ';
    display: block;
    height: var(--size);
    width: var(--size);
    position: absolute;
    top: calc(var(--size) * 0.125);
    left: 0;
    background: var(--background);  
  }

  .checkbox-wrapper-29 .checkbox__label:after {
    content: ' ';
    display: block;
    height: var(--size);
    width: var(--size);
    border: calc(var(--size) * .14) solid var(--text-brand-2);
    transition: 200ms;
    position: absolute;
    top: calc(var(--size) * 0.125);
    left: 0;
    background: var(--background);  
  }

  .checkbox-wrapper-29 .checkbox__label:after {
    transition: 100ms ease-in-out;
  }

  .checkbox-wrapper-29 .checkbox__input:checked ~ .checkbox__label:after {
    border-top-style: none; 
    border-right-style: none;
    -ms-transform: rotate(-45deg); /* IE9 */
    transform: rotate(-45deg);
    height: calc(var(--size) * .5);
    border-color: var(--text-brand);
  }

  .checkbox-wrapper-29 .checkbox {
    position: relative;
    display: flex;
    cursor: pointer;
    /* Mobile Safari: */
    -webkit-tap-highlight-color: rgba(0,0,0,0);   
    color: var(--text-white);
    font-size: 11px;
    font-family: 'Poppins', sans-serif;
  }

  .checkbox-wrapper-29 .checkbox__label:after:hover,
  .checkbox-wrapper-29 .checkbox__label:after:active {
     border-color: var(--text-brand); 
  }

  .checkbox-wrapper-29 .checkbox__label {
    margin-right: calc(var(--size) * 0.45);
  }
.checkbox-label{
  margin-left: 2px;
  display: inline-block;
}

.checkbox-label a{
  color: var(--text-brand);
  text-decoration: none;
}

.social-message {
  width: 100%;
  display: flex;
  align-items: center;
}

.line {
  height: 1px;
  flex: 1 1 0%;
  background-color: rgba(55, 65, 81, 1);
}
.line {
  width: 100%;
  height: 1px;
  background: var(--text-gray-4);
  margin: 5px 0;
}
.social-message .message {
  margin-bottom: -1px;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 10px;
  color: var(--text-gray-5);
}

.chart-grid-plto {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 20px;
  margin-top: 20px;
}
.tables-plto  {
  gap: 20px;
  margin-top: 20px;
}
.chart-plto, .table-x-plto {
  margin-bottom: 40px;
  background-color: var(--bg-color-3);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 20px;
}

.chart-plto h2, .table-x-plto h3 {
  color:#f3f3f3;
  font-size: 1.2rem;
  margin-top: 0;
}
.table-plto {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.table-plto th, .table-plto td {
  text-align: left;
  padding: 12px;
  font-size: 12px;

  border-bottom: 1px solid var(--bg-color-3);
}

.table-plto th {
  background-color: var(--bg-color-3);
}
.table-plto tr{
  background-color: var(--bg-color-1);
  color: var(--text-gray-4);
}
.table-plto tr:nth-child(even) {
  background-color: var(--bg-color-1);
  color: var(--text-gray-4);

}
.header-plto {
  background-color: var(--bg-color-2);
  color: #ecf0f1;
  padding:  10px 20px 10px 20px;
  position: sticky;
  top: 0;
  z-index: 1000;
}
.nav-plto ul {
  margin:0 auto;
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: start;
}
.nav-plto ul li .btn{
  background-color: var(--bg-color-5);
  color: #ecf0f1;
  padding: 0.5rem 1rem !important;
  border-radius: 5px;
  text-decoration: none;
  padding: 10px !important;
  height: 20px !important;
  min-width: 50px !important;
  transition: background-color 0.3s;
}
.nav-plto ul li .btn-brand{
  background-color: var(--bg-color-4);
  color: #ecf0f1;
  padding: 0.5rem 1rem !important;
  border-radius: 5px;
  text-decoration: none;
  padding: 10px !important;
  height: 20px !important;
  min-width: 50px !important;
  background-color: var(--text-brand);
  transition: background-color 0.3s;
}
.nav-plto ul li a {
  color: #ecf0f1;
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s;
}

.nav-plto ul li a:hover, .nav-plto ul li a.active {
  background-color: #34495e;
}

.invoice-short-info {
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
}

.invoice-short-info label {
  color: #00000099;
}

.invoice-short-info .text-black {
  font-weight: 500;
}

.issuer-recipient-block {
  column-gap: 32px;
  display: flex;
  margin-bottom: 16px;
  font-size: 10px;
  color: #212121;
  line-height: 14px;
  padding: 0 6px;
}

.issuer-recipient-block > div {
  flex: 1;
}

.issuer-recipient-block .title {
  color: #4432a9;
  margin-bottom: 6px;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}

.issuer-recipient-block .address-block {
  margin-bottom: 6px;
}

.description-block {
  width: 50%;
  margin-bottom: 16px;
  line-height: 14px;
  padding: 0 6px;
}

.payment-info {
  column-gap: 32px;
  display: flex;
  margin-bottom: 16px;
  background-color: #f7f7fc;
  border-radius: 7.367px;
  align-items: center;
}

.payment-info > div {
  flex: 1;
}

.payment-info-rows {
  color: #000;
  margin: 11px 6px;
  line-height: 14px;
  font-size: 10px;
  word-break: break-all;
}

.payment-info-right {
  margin: 11px 0;
  display: flex;
  gap: 4px;
}

.payment-info-right img {
  height: 62px;
}

.payment-info .qr-description {
  width: 98px;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  align-items: center;
  display: flex;
}

.payment-info-title {
  margin-bottom: 8px;
  padding: 0 6px;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  color: #4432a9;
}

.summary-additional-info {
  display: flex;
  column-gap: 32px;
  break-inside: avoid;
}

.summary-additional-info > div {
  flex: 1;
}

.additional-info-wrap {
  border: 1px solid #f7f7fc;
  border-radius: 4px;
}

.additional-info {
  padding: 8px 6px;
}

.additional-info .label {
  line-height: 14px;
  margin-bottom: 4px;
}

.summary-table {
  line-height: 14px;
  font-size: 10px;
  color: #000000de;
}

.summary-table > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.summary-table .summary-table-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.summary-table .summary-table-item:last-child {
  border-bottom: none;
}

.summary-table .summary-table-item {
  background-color: #f8f7fc;
  min-height: 18px;
  padding: 0 6px;
  border-bottom: 1px solid #f7f7fc;
}

.summary-table .total-amount-item {
  border-radius: 4px;
  background-color: #f7f7fc;
  color: #4432a9;
  font-size: 12px;
  font-weight: 700;
  min-height: 26px;
  margin-bottom: 2px;
  padding: 0 6px;
}

.summary-table .exchange-rate-item {
  min-height: 14px;
  background-color: initial;
  color: #9e9e9e;
  padding: 0 6px;
}

.invoice-items {
  width: 100%;
  margin-bottom: 8px;
}

.invoice-items td,
.invoice-items th {
  padding: 6px;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
}

.invoice-items td {
  color: #000000de;
  background: #f7f7fc;
}
.invoice-container {
  margin: 0;
  height: auto;
  min-height: 940px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
text-black {
  color: #000000de;
  font-weight: 500;
}

.label {
  color: #9e9e9e;
  font-weight: 500;
}


.container-inv {
  background-color: #fff;
  overflow: visible;
  min-height: 1023px;
  width: 654px;
  height: 1023px;
}

dt,
dd {
  display: inline;
  margin: 0;
}

dt::before {
  content: "";
  display: block;
}

dt::after {
  content: "";
}
table.header-rounded-corners th {
  border: 0 solid #e8e6f4;
}

table.header-rounded-corners thead tr:last-child th:first-child,
table.header-rounded-corners tbody tr:last-child td:first-child {
  border-bottom-left-radius: 5px;
}

table.header-rounded-corners thead tr:last-child th:last-child,
table.header-rounded-corners tbody tr:last-child td:last-child {
  border-bottom-right-radius: 5px;
}

.invoice-items tbody:nth-child(2) tr:first-child td {
  border-top: none;
}

th {
  text-align: left;
}

#header-inv table {
  min-height: 82px;
}

@page {
  size: A4 portrait;
}
.bodypart-inv , .bodypart-inv td {
  background-color: #fff !important;

}
.user-details-stats {
  display: flex;
}
.user-details-stats .card {
  width: 136px;
  padding: 10px;
  background-color: var(--bg-color-3);
  box-shadow: none;
  border-radius: 6px;
  margin: 0px;
  margin: 10px 5px;
  display: flex;
  color: var(--text-white);
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.user-details-stats .card p:nth-child(2) {
  font-size: 10px;
  color: var(--text-gray-4);
  margin-bottom: 0px;
}
.user-details-table-top,
.user-details-table-elements {
  color: var(--text-brand);
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid #344e6f33;
  gap: 15px;
  height: 40px;
}
.user-details-table-top .right,
.user-details-table-elements .right {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}
.user-details-table-elements .left {
  display: flex;
  align-items: center;
  justify-content: start;
  min-width: 240px;
  width: 100%;
}
.user-details-table-elements .left p {
  margin-bottom: 0px;
  max-width: 140px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-details-table-elements .left p img {
  height: 10px;
  margin-right: 0px;
}
.user-details-table-elements .left i {
  padding-right: 10px;
  padding-left: 3px;
  font-size: 10px;
}
.user-details-table-elements .left .earning-container {
  display: flex;
  align-items: center;
  justify-content: start;
}
.user-details-table-elements .left .earning-name {
  width: auto;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: var(--text-white);
}
.user-details-table-elements .left .earning-name:hover {
  color: var(--text-brand);
  cursor: pointer;
}
.user-details-table-top div {
  min-width: 50px;
  width: 100%;
  font-size: 14px;
}
.user-details-table-elements div {
  padding-top: 3px;
  min-width: 50px;
  width: 100%;
  font-size: 11px;
}
.user-details-table-top .right div:nth-child(1),
.user-details-table-elements .right div:nth-child(1) {
  text-align: left;
  min-width: 50px;
}
.user-details-table-top .right div:nth-child(2),
.user-details-table-elements .right div:nth-child(2) {
  text-align: right;
  min-width: 50px;
}
.user-details-table-elements .right div:nth-child(1) {
  text-align: left;
  font-size: 11px;
  min-width: 110px !important;
}
.user-details-table-elements .right div:nth-child(1) a {
  color: var(--text-white);
  text-decoration: underline;
}
.user-details-table-elements .right div:nth-child(2) {
  color: var(--text-white);
  font-size: 13px;
  font-weight: bold;
}
.user-details-table-elements img {
  border-radius: 5px;
  height: 25px;
  margin-right: 5px;
}
.user-details-stats {
  display: flex;
}
.admin-a:hover {
  color: var(--text-white) !important;
}

.page-item {
  cursor: pointer;
  border-radius: 5px;
}
.page-item a {
  font-size: 12px !important;
  padding: 0 10px !important;
  border-radius: 10px;
  min-width: 30px;
  margin: 0 3px;
  background-color: var(--bg-color-3);
  color: var(--text-primary) !important;
  border: none !important;
}
.page-item a:hover {
  background-color: var(--bg-color-6);
}
.pagination li.active {
  background-color: transparent;
}
.page-item.active .page-link {
  z-index: 3;
  color: #000 !important;
  background-color: var(--text-brand);
}
.page-item:last-child .page-link,
.page-item:first-child .page-link {
  border-radius: 10px;
}
.pagination {
  margin-top: 15px;
  display: flex;
  overflow: auto hidden;
  width: 100%;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 400px) {
  .pagination {
    justify-content: start;
  }
}
input.form-control {
  background-color: var(--bg-color-6);
  border: 1px solid black;
  border-radius: 0px;
  color: var(--text-primary);
  padding: 5px 10px;
  font-size: 12px;
  width: 100%;
}

.login-modal,
.register-modal {
  max-height: 90vh;
  overflow: auto;
}
.form-container {
  position: relative;
  top: 10px;
  width: 380px;
  border-radius: 10px;
  background-color: var(--bg-color-3);
  padding: 2rem;
  color: rgba(243, 244, 246, 1);
  overflow: auto;
}

.title {
  text-align: center;
  font-size: 35px;
  line-height: 2rem;
  margin-bottom: 10px;
}
@media print {
  body {
    min-height: unset;
    padding: 0;
    margin: 0;
  }

  #header-inv,
  #header-inv-space {
    height: 100px;
  }

  #footer-inv,
  #footer-inv-space {
    height: 54px;
  }

  #header-inv {
    position: fixed;
    top: 0;
  }

  #footer-inv {
    position: fixed;
    bottom: 0;
  }
}


#footer-inv {
  margin-top: auto;
  width: 100%;
  color: #746a90;
  font-size: 7px;
  display: flex;
}

#footer-inv .footer-inv-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 6px;
}

#footer-inv .footer-inv-right {
  display: flex;
  align-items: center;
}

#footer-inv .footer-inv-right img {
  width: 40px;
  padding-top: 5px;
  margin-right: 6px;
}

#header-inv {
  padding: 0 6px;
  display: flex;
  align-items: center;
}

#header-inv h1 {
  font-weight: 700;
  font-size: 24px;
  color: #4432a9;
  margin: 0;
  word-break: break-word;
  padding-right: 80px;
}

#header-inv .divider {
  width: 32px;
}

#header-inv .divider > div {
  height: 64px;
  width: 0;
  margin: auto;
  border: 1px solid #c8c4d4;
}

#header-inv .logo {
  height: 78px;
  max-width: 244px;
}

#header-inv .logo img {
  height: 100%;
  width: auto;
  max-width: 244px;
}
.purple-bg, .purple-bg tr {
  background-color: #e8e6f4 !important;
}

.purple-bg th,
.purple-bg td {
  color: #4432a9;
}

.soft-purple-bg {
  background: #f8f7fc;
}

.soft-purple-bg th,
.soft-purple-bg td {
  color: #4432a9;
}

.invoice-item{
  background-color: #f7f7fc !important;
}


.issuer-recipient-block {
  column-gap: 32px;
  display: flex;
  margin-bottom: 16px;
  font-size: 10px;
  color: #212121;
  line-height: 14px;
  padding: 0 6px;
}

.issuer-recipient-block > div {
  flex: 1;
}

.issuer-recipient-block .title {
  color: #4432a9;
  margin-bottom: 6px;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
}

.issuer-recipient-block .address-block {
  margin-bottom: 6px;
}

.description-block {
  width: 50%;
  margin-bottom: 16px;
  line-height: 14px;
  padding: 0 6px;
}

.payment-info {
  column-gap: 32px;
  display: flex;
  margin-bottom: 16px;
  background-color: #f7f7fc;
  border-radius: 7.367px;
  align-items: center;
}
.toastify-success {
  font-family: 'Popins', sans-serif;
  background: var(--toastify-color-success) !important;
  color: #fff !important;
  border-radius: 10px !important;
  padding: 6px !important;
  font-size: 13px;
  font-family: Roboto;
  line-height: 18px;
  z-index: 100000000;
}
.toastify-error {
  font-family: 'Popins', sans-serif;

  background: var(--toastify-color-error) !important;
  color: #fff !important;
  padding: 6px !important;
  border-radius: 10px !important;
  font-family: Roboto;
  font-size: 13px;
  line-height: 18px;
  z-index: 100000000;
}
.Toastify__toast-icon {
  font-size: 19px;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

.box-event-container{
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
}

.blur-event-box::after{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(5px);
  transition: ease 0.3s;
  border-radius: var(--default-border-radius);

}

.box-event:hover{
  transition: ease 0.3s;
  transform: scale(1.1);
  cursor: pointer;
}

.box-event .badge-img-event-main, .box-event, .blur-event-box{
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: var(--default-border-radius);
}
.badge-img-event{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0px;
}
