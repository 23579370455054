
.top-3-leaderboard {
    position: relative;
    z-index: 2;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: -73px;
    gap: 50px;
    width: 100%;
  }
  
  .top-banner-lb:first-child,
  .top-banner-lb:last-child {
    margin-top: 150px;
  }
  
  .top-banner-lb {
    position: relative;
    width: 190px;
    border-radius: 10px;
    background-color: var(--accent-4);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 0px;
  }
  
  .top-banner-lb img {
    z-index: 2;
    border-radius: 50px;
    width: 80px;
  }
  
  .top-banner-lb .name {
    color: #c0c3c8;
    margin: 10px 0 -5px 0;
  }
  
  .top-banner-lb .points {
    color: #fff;
    margin: 0px 0 10px 0;
    font-size: 15px;
    display: flex;
    font-weight: bold;
    align-items: center;
    gap: 5px;
  }
  .top-banner-lb .reward p {
    margin: 0;
    display: flex;
    align-items: center;
    background-color: #1c2d45ed;
    padding: 3px 10px;
    border-radius: 4px;
  }
  .top-banner-lb .reward img {
    border-radius: 0px;
    width: 70px;
  }
  .top-banner-lb .reward {
    background: #111923;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 80%;
    border-radius: 4px;
    color: #21a8b4;
    font-size: 13px;
  }
  .lb_crown {
    position: absolute;
    top: -20px;
    z-index: 1;
    border-radius: 0px !important;
    width: 70px !important;
  }
  .lb_place {
    width: 40px !important;
    border-radius: var(--default-border-radius);
    font-size: 12px;
    text-align: center;
    padding: 5px 10px;
    margin-left: 10px;
    font-family: 'Lato', sans-serif;
  }
  .profile-details-activity-item p{
    display: flex;
    font-family: 'Lato', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
  }
  .leaderboard-reward-image{
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
  .time-remaining-lb::before {
    content: "Time remaining";
    display: block;
    font-size: 13px;
    color: var(--text-brand);
  }
  .time-remaining-lb {
    font-size: 25px;
    font-weight: bold;
    color: var(--text-white-2);
    text-align: center;
  }
  .user-position {
    background-color: var(--accent-4);
    width: 600px;
    margin: 0 auto;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 730px) {
    .top-3-leaderboard {
      display: flex;
      flex-direction: column;
      margin-top: 0px;
    }
    .top-banner-lb:first-child,
    .top-banner-lb:last-child {
      margin-top: 0px;
    }
    .top-banner-lb {
      width: 80%;
    }
    .time-remaining-lb {
      margin-top: 45px;
    }
    .user-position {
      width: 90%;
      text-align: center;
    }
  }
  .user-position p {
    display: inline;
    margin: 0px;
    color: var(--text-white-2);
  }
  .cyan-color {
    color: var(--text-brand) !important;
    background: none;
  }
  .table {
    display: block;
    padding-bottom: 10px;
  }
  .leaderboard-table {
    position: relative;
    z-index: 2;
    border-spacing: 0 6px;
    border-collapse: separate;
    width: 80%;
    max-width: 1300px;
    margin: 0 auto;
    margin-top: 40px;
  }
  .leaderboard-table th,
  .leaderboard-table tr {
    border: 0px;
  }
  .leaderboard-table td {
    font-weight: 400;
  }
  .leaderboard-table thead {
    color: #93acd3;
  }
  .amount-leaderboard{
    display: inline-block;
    margin-left: 4px;
    font-size: 14px;
    margin-bottom: 3px;
    font-family: 'Lato', sans-serif;
    color: var(--text-white-2) !important;
    font-weight: 300;
    
  }
  .leaderboard-banner{
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    background: var(--bg-color-3);
    width: 100%;
    padding:15px;
    border-radius: var(--default-border-radius);
    margin-bottom: 20px;
  }

  .leaderboard-banner-title{
    color: var(--text-white);
    font-size: 15px;
    font-family: 'Lato', sans-serif;
    margin-bottom: 0px;
    text-transform: capitalize;
  }
  .leaderboard-banner-description{
    margin-top: 10px;
    color: var(--text-white-2);
    font-size: 13px;
    font-family: 'Lato', sans-serif;
    margin-bottom: 0px;
    text-align: justify;
  }