
.offers-container{
    width: calc(100% - 0px);
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.offers-search{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.offers-search-left{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.offers-search-left h1{
    color: var(--text-white);
    font-size: 20px;
    margin-bottom: 0px;
    font-family: "Lato", sans-serif;
    font-weight: 600;
    text-transform: capitalize;
}
.sort-menu-box {
    padding: 20px 10px;
    position: absolute;
    background: var(--bg-color-3);
    height: auto;
    width: 176px;
    top: 30px;
    right: -20px;
    overflow: auto;
    border-radius: 0 0 var(--default-border-radius) var(--default-border-radius);
    font-size: 14px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .sort-menu-box .header-menu-box-item {
    color: var(--text-white-2);
    padding: 0px 10px;
    height: 20px;
    font-size: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    cursor: pointer;
    flex-direction: row;
    gap: 5px;
    transition: color 0.3 ease;
  }
  .sort-menu-box .header-menu-box-item:hover {
    color: var(--text-white-3);
    transition: ease 0.3s;
  }

  .offers-cont-sort{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative;
    background: var(--bg-color-3);
    border-radius: var(--default-border-radius);
    color: var(--text-white-2);
    padding: 9px 20px;
  }
  .deviceSelectorBTN {
    border-radius: 10px;
    color: var(--text-white-2);
    gap: 2px;
    overflow: auto;
    cursor: pointer;
    position: relative;
    width: auto;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 15px;
  }

  .offers-cont-list{
    display: grid;
    grid-template-rows: repeat(auto-fill, minmax(130px, 1fr));
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    gap: 20px;
  }