.profile-top-user{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}
.profile-bottom{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}
.profile-badge-info{
    width: 100%;
    background-color: var(--bg-color-3);
    padding: 10px 20px;
    border-radius: var(--default-border-radius);
}
.profile-badge-info-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile-badge-info-item-title{
    color: var(--text-white-2);
    font-size: 14px;
    font-family: 'Lato', sans-serif;
}

.profile-badge-info-item-data{
    color: var(--text-gray-4);
    font-size: 14px;
    font-family: 'Lato', sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.profile-badge-info-item-data img{
    width: 15px;
    height: 15px;
    margin-right: 5px;
    margin-top: 3px;
    border-radius: 50%;
}
.profile-top-user .daily-rewards-header,  .profile-top-left .daily-rewards-header{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
    margin-bottom: 10px;
}
@media screen and (max-width: 800px){
    .profile-top-left .user-modal-content-header-mid-section-item{
        width: 100%;
    }
    .profile-top-user .daily-rewards-header,  .profile-top-left .daily-rewards-header{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        margin-bottom: 10px;
    }
    .profile-top-left .user-modal-content-header-mid-section-item .user-modal-content-header-mid-section-item-title{
        font-size: 14px;
    }
    .profile-badge-info{
        width: 100%;
       
    }
    
}
@media screen and (max-width: 560px){
    .profile-top-user .daily-rewards-header,  .profile-top-left .daily-rewards-header{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        margin-bottom: 10px;
    }
    .profile-top-left .user-modal-content-header-mid-section-item .user-modal-content-header-mid-section-item-title{
        font-size: 14px;
    }
    .profile-badge-info-item{
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
    }
    
}
@media screen and (max-width: 340px){
    .profile-top-user .daily-rewards-header,  .profile-top-left .daily-rewards-header{
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
        margin-bottom: 10px;
    }
    
}
.profile-top-user-name{
    font-size: 20px;
    color: var(--text-white-2);
    font-family: 'Lato', sans-serif;
    margin-bottom: 0px;
}
.img-profile{
    width: 80px;
    height: 80px;
    border-radius: 50%;
}


.profile-setting-section{
    width: 100%;
    background: var(--bg-color-3);
    border-radius: var(--default-border-radius);
    padding: 20px;
    margin-bottom: 20px;
}

.profile-setting-section-title{
    color: var(--text-white-2);
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    margin-bottom: 15px;
}

.profile-setting-section-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.profile-setting-section-item-2{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    gap: 10px;
}
.profile-setting-section-item-data{
    display: flex;
    align-items: start;
    flex-direction: column;

}
.profile-setting-section-item-data-title{
    color: var(--text-white-2);
    font-size: 14px;
    font-family: 'Lato', sans-serif;
}
.profile-setting-section-item-data-description{
    color: var(--text-gray-4);
    font-size: 12px;
    font-family: 'Lato', sans-serif;
}
.profile-setting-section-item-badge-green{
    width: 100%;
    background: #3d986b6d;
    padding: 5px;
    color: var(--text-white-3);
    font-size: 12px;
    font-family: 'Lato';
    border-radius: var(--default-border-radius);
    text-align: center;
}

.profile-setting-section-item-badge-red{
    width: 100%;
    background: #983d3d6d;
    padding: 5px;
    color: var(--text-white-3);
    font-size: 12px;
    font-family: 'Lato';
    border-radius: var(--default-border-radius);
    text-align: center;
}