.modal-main{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #0000009f;
    backdrop-filter: blur(5px);
    z-index: 100;
    top: 0;
    left: 0;
    padding: 50px 0;
}

.modal-content, .modal-content-wall{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 100%;
    z-index: 100;
    margin: 0 auto;
    overflow: auto;
    background-color: var(--bg-color-1);
    border-radius: var(--default-border-radius);
}


.modal-content-header{
    display: flex;
    position: sticky;
    z-index: 2;
    top: 0;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px;
    background: var(--bg-color-3);
    border-radius: var(--default-border-radius) var(--default-border-radius) 0 0 ;

}

.modal-content-header-title{
    font-size: 16px;
    font-family: "Inter Tight", sans-serif;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    color: var(--text-white-2);
}
.modal-content-header-close-fixed svg{
    position: absolute;
    right: 5px;
    top:5px;
}
.modal-content-header-close svg{
    fill: var(--text-white-2);
    background-color: var(--bg-color-6);
    border-radius: var(--default-border-radius);
    height: 25px;
    width: 25px;
    padding: 5px;
    transform: rotate(180deg);
}

.modal-content-header-close:hover svg{
    cursor: pointer;
    background-color: var(--bg-color-6);
}
.modal-content-body{
    height: 100%;
}
.modal-content-promoted-offer{
    position: relative;
    max-width: 800px;
    overflow-y: scroll;
}
.modal-content::-webkit-scrollbar {
    width: 1px;
    height: 4px;
  }
  
  /* Track */
  
  .modal-content::-webkit-scrollbar-track {
    background: var(--bg-color-2);
  }
  
  /* Handle */
  
  .modal-content::-webkit-scrollbar-thumb {
    background: var(--bg-color-6);
  }
  
  /* Handle on hover */
  
  .modal-content::-webkit-scrollbar-thumb:hover {
    background: var(--bg-color-6);
  }

.modal-content-promoted-offer-banner{
    position: relative;
    width: 100%;
    height: 200px;
    background-color: var(--bg-color-3);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.modal-content-promoted-offer-banner img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.modal-content-promoted-offer-banner::after{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background:linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.3) 50%, var(--bg-color-1) 80%);
    backdrop-filter: blur(1px);
}
.modal-content-promoted-offer-info-header{
    width: 100%;
    position: absolute;
    top: 160px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
}
.modal-content-promoted-offer-info-header-left{
    display: flex;
    align-items: center;
    padding: 20px;
    gap: 20px;
   
}

.modal-content-promoted-offer-info-header-image img{
    width: 70px;
    height: 70px;
    border-radius: var(--default-border-radius);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}
.modal-content-promoted-offer-info-header-reward{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
}
.modal-content-promoted-offer-info-header-reward img{
   margin-bottom: 6px;
}
.modal-content-promoted-offer-info-header-reward-amount{
    font-size: 19px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: var(--text-white);
}

.modal-content-promoted-offer-info-header-data{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 5px;
}

.modal-content-promoted-offer-info-header-reward-popularity{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: self-start;
}
.modal-content-promoted-offer-info-header-reward-popularity-title{
    font-size: 10px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: var(--text-gray-4);
}

.modal-content-promoted-offer-info-header-reward-popularity-icon svg{
    fill: var(--bg-color-6);
}

.fill-star-yellow{
    fill: #f1b151 !important;
}
.modal-content-promoted-offer-info-header-right{
    display: flex;
    flex-direction: column;
    align-items: self-end;
    justify-content: end;
    gap: 5px;
    margin-top: 32px;
    margin-right: 20px;

}
.modal-content-promoted-offer-info-header-platforms-title{
    font-size: 10px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: var(--text-gray-4);
}
.modal-content-promoted-offer-info-header-platforms-icon svg{
    fill: var(--text-white-2);
    background-color: var(--bg-color-4);
    border-radius: 50%;
    height: 25px;
    width: 25px;
    padding: 4px;
}
.modal-content-promoted-offer-info-mid{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 20px;
}

.modal-content-promoted-offer-info-mid-item{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    width: 50%;
    gap: 10px;
    background: var(--bg-color-3);
    border-radius: var(--default-border-radius);
    padding: 5px 10px;
}
.modal-content-promoted-offer-info-mid-item-title{
    font-size: 11px;
    font-family: "Poppins", sans-serif;
    font-weight:400;
    color: var(--text-gray-4);
}

.modal-content-promoted-offer-info-mid-item-data svg{
    fill: var(--text-brand);
}
.modal-content-promoted-offer-info-mid-item-data img{
    width: 30px;
    height: 30px;
    border-radius: var(--default-border-radius);
    padding: 5px;
}
.modal-content-promoted-offer-info-mid-item-data{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 5px;
    font-size: 15px;
    height: 30px;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: var(--text-white-2);
}
.modal-content-promoted-offer-info{
    min-height: 45%;
    height: 45%;
}
.modal-content-promoted-offer-info-description{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
    background-color: var(--bg-color-3);
    border-radius: var(--default-border-radius);
    padding: 10px ;
    font-family: "Poppins", sans-serif;
    font-size: 10px;
    color: var(--text-gray-4);   
    text-align: justify; 
}
.modal-content-promoted-offer-info-bottom-container{
    height: 100%;
}
.modal-content-promoted-offer-info-bottom{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: var(--bg-color-3);
    border-radius: 0 var(--default-border-radius) var(--default-border-radius) 0;
}

.modal-content-promoted-offer-info-bottom{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    border-radius: var(--default-border-radius);
    padding: 10px;
}
.modal-content-promoted-offer-info-bottom-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    overflow: auto;
    width: 100%;
    padding: 8px 0 ;
    border-bottom: 2px solid var(--bg-color-1);
    gap: 20px;
}
.modal-content-promoted-offer-info-bottom-item::-webkit-scrollbar {
    width: 1px;
    height: 4px;
  }
  
  /* Track */
  
  .modal-content-promoted-offer-info-bottom-item::-webkit-scrollbar-track {
    background: var(--bg-color-2);
  }
  
  /* Handle */
  
  .modal-content-promoted-offer-info-bottom-item::-webkit-scrollbar-thumb {
    background: var(--bg-color-6);
  }
  
  /* Handle on hover */
  
  .modal-content-promoted-offer-info-bottom-item::-webkit-scrollbar-thumb:hover {
    background: var(--bg-color-6);
  }
.modal-content-promoted-offer-info-bottom-item:last-child{
    border-bottom: none;
}
.modal-content-promoted-offer-info-bottom-left{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;

}
.modal-content-promoted-offer-info-bottom-item-reward img{
    width: 12px;
    height: 12px;
    border-radius: var(--default-border-radius);
    padding: 2px;
    margin-right: 2px;
    margin-bottom: 1px;
}
.modal-content-promoted-offer-info-bottom-item-reward{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #4dcbee14;
    padding: 5px 7px 4px 5px;
    color: #5690a0c5;
    font-size: 10px;
    font-family: "Inter Tight", sans-serif;
    border-radius: var(--default-border-radius);
}
.modal-content-promoted-offer-info-bottom-item-title{
    font-size: 14px;
    color: var(--text-gray-4);
    font-family: "Inter Tight", sans-serif;
    overflow: hidden;
    font-weight: 300;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
}

.modal-content-promoted-offer-info-bottom-right svg{
    fill: var(--text-brand);
    font-size: 22px;
}
.modal-content-promoted-offer-button{
    position: sticky;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 10px;
    color: var(--text-white);
    background: #a43d3d;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
}

.modal-content-promoted-offer-button:hover{
    position: sticky;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 10px;
    color: var(--text-white-2);
    background: #8e3232;
    transition: ease 0.3s;
    cursor: pointer;
}

.phone-numb-v-ask-cont{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: var(--text-white-2);
    font-size: 20px;
    text-align: center;
    font-family: "Poppins", sans-serif;
}
.phoneNumberBTN{
    background: #8e3232;
    color: var(--text-white-2);
    cursor: pointer;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    padding: 10px 20px;
    position: absolute;
    width: 100%;
    bottom: 0;
}
.phoneNumberBTN:hover{
    background: #a43d3d;
    transition: ease 0.3s;
}

.modal-content-user{
    z-index: 100;

    max-width: 550px;
    padding: 10px 20px;
}
.modal-content-user .modal-content-body{
    padding: 10px;
    margin-top: 20px;
    
}
.user-modal-content-header-left-image{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 20px;
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 20px;
}
.user-modal-content-header-left-image::after{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background:linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.2) 50%,  rgba(0, 0, 0, 0.3)  80%);
    border-radius: 50%;
}

.user-modal-content-header-left-image img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
}

.user-modal-content-header-left-image img::after{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background:linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.3) 50%, var(--bg-color-1) 80%);
    backdrop-filter: blur(1px);
    border-radius: 50%;
}

.user-modal-content-header-left{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}
.user-modal-content-header-left-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 5px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.user-modal-content-header-left-info-name{
    font-size: 23px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: var(--text-white);
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.user-modal-content-header-left-info-joined{
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: var(--text-gray-4);
}
.user-modal-content-header-left-info-level{
    display: inline;
    font-size: 10px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: var(--text-gray-4);
    background-color: #8e32323f;
    padding: 5px 10px;
    border-radius: var(--default-border-radius);
    color:#ca5050;
    width: 100%;
    text-align: center;
}
.user-modal-content-header-left-rank-badge{
    display: inline;
    margin-right: 10px;
    
}
.user-modal-content-header-left-rank-badge img{
    display: inline;
    width: 20px;

}

.user-modal-content-header-left-info-line{
    display: block;
    width: 100%;
    height: 30px;
}
.user-modal-content-header-left-country{
    margin-left: 10px;
   width: 25px;
    height: 25px;
    width: 40px;
    border-radius: var(--default-border-radius);
    background: var(--bg-color-3);
    padding: 7px;
}

@media screen and (max-width:750px) {
    .modal-main{
        width: 100%;
        padding: 0px;
        padding-top: 100px;
        transition: ease 0.3s;
    }

    .modal-content, .modal-content{
        border-radius:var(--default-border-radius) var(--default-border-radius) 0 0 ;
        width: 100%;
        transition: ease 0.3s;
        max-width: 100%;
    }
}
.user-modal-content-header-mid{
    display: flex;
    flex-direction: column; 
    justify-content: space-between;
    align-items: start;
    gap: 30px;
    margin: 30px 0;
    width: 100%;
    border-bottom: 1px solid var(--bg-color-1);
}
.user-modal-content-header-mid-section{
    width: 100%;
}

.user-modal-content-header-section-title{
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: var(--text-white-2);
    margin-bottom: 10px;
}

.user-modal-content-header-mid-section-items{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.user-modal-content-header-mid-section-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 5px;
    padding: 10px 20px;
    background: var(--bg-color-3);
    border-radius: var(--default-border-radius);
    width: 100%;

    overflow: hidden;
}
.user-modal-content-header-mid-section-item-data{
    font-size: 11px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: var(--text-gray-4);
}
.user-modal-content-header-mid-section-item-title{
    font-size: 18px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    color: var(--text-white-2);
}

.user-modal-content-header-mid-section-items-group{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 10px;
    width: 100%;
}

.ticket-design{
    fill: rgb(197, 148, 57);
    transform: rotate(130deg);  
    font-size: 15px;
    margin-right: 2px;
    margin-bottom: 4px;
}
.profile-details-activity-item{
    max-width: 200px;
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
}

.profile-details-activity-item img{
    width: 30px;
    height: 30px;
    border-radius: var(--default-border-radius);
    padding: 5px;
    margin-left: 5px;
    background: var(--bg-color-4);
}

.profile-details-activity-item p{
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    color: var(--text-gray);
    margin: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.reward-up-modal{
    padding-left: 10px;
    color: var(--text-white-2);
    font-size: 12px;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: start;
    flex-direction: row;
    margin-bottom: 0px;
}


.modal-content-small{
    padding: 20px;
    width: 300px;
    height:auto;
    min-height: 200px;
    margin-top: 15%;
    background:linear-gradient(130deg, rgb(12 35 45) 0%, rgb(20 24 27) 50%, var(--bg-color-1) 80%);
}
.modal-content-small-2{
    padding: 20px;
    width: 400px;
    height:auto;
    min-height: 400px;
    margin-top: 15%;
    background:linear-gradient(130deg, rgb(12 35 45) 0%, rgb(20 24 27) 50%, var(--bg-color-1) 80%);
}

.modal-content-medium{
    padding: 20px;
    width: 500px;
    height:auto;
    min-height: 300px;
    margin-top: 15%;
    background:linear-gradient(130deg, rgb(12 35 45) 0%, rgb(20 24 27) 50%, var(--bg-color-1) 80%);
}

@media screen and (max-width:600px){
    .modal-content-small{
        width: 100%;
        min-height: 100%;
    }
    .modal-content-medium{
        width: 100%;
        min-height: 100%;

    }
    
}

.modal-content-medium .modal-content-sized-container-text{
    margin-top: 30px;
}
.modal-content-medium .modal-content-sized-container-text div{
    text-align: start;
}
.modal-content-small .modal-content-header-close{
    display: none;
}
.modal-content-sized-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.modal-content-medium .modal-content-sized-container{
    display: flex;
    justify-content: start;
    align-items: start;
}
.modal-content-sized-container-text-title{
    font-size: 20px;
    font-family: "Lato", sans-serif;
    font-weight: 500;
    color: var(--text-white);
    text-align: start;
}
.modal-content-sized-container-text-description{
    display: inline;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    color: var(--text-white-2);
    text-align: center;
}
.modal-content-sized-container-text-description a{
    color: var(--text-brand);
    text-decoration: none;
    cursor: pointer;
}
.modal-content-sized-container-image img{
    max-width: 170px;
    min-height: 50px;
}

.modal-content-sized-container-buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-top: 10px;
    border-top: 1px solid var(--bg-color-6);
}

.modal-content-sized-container-buttons-button{
    padding: 9px 20px;
    color: var(--text-white-2);
    background: var(--bg-color-6);
    cursor: pointer;
    font-family: "Lato", sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    border-radius: var(--default-border-radius);
    margin-top: 20px;
}

.modal-content-sized-container-buttons-button:hover{
    color: var(--text-white-3);
    transition: ease 0.3s;
}
.modal-content-medium .modal-content-header-close svg{
    right:0px;
    top:0px;
}

.modal-content-login, .modal-content-register, .modal-content-forgot-password, .modal-content-reset-password{
    width: 400px;
    display: inline;
    background: transparent;
}
@media screen and (max-width:500px) {
    .modal-content-login, .modal-content-register, .modal-content-forgot-password, .modal-content-reset-password{
        width: 100%;
        display: inline;
        background: transparent;
    }
}
.modal-content-login .modal-content-body, .modal-content-register .modal-content-body, .modal-content-forgot-password .modal-content-body, .modal-content-reset-password .modal-content-body{
    height: auto;
    min-height: 100px;
    display: block;
    background-color: var(--bg-color-1);
}
.modal-content-login-data {
    font-family: 'Popins', sans-serif;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: var(--default-border-radius);
    color: var(--text-white);
    margin: 0 auto;
    gap: 20px;
}

.modal-content-login-data .sc-right-box-3-el{
    font-size: 11px;
    font-family: "Poppins", sans-serif;
    color: var(--text-gray-5);
}

.modal-content-login-data .sc-right-box-3-el a{
    color: var(--text-brand);
    text-decoration: none;
    cursor: pointer;
}
.modal-content-login-data .sc-right-box-2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    border-bottom: 1px solid var(--text-gray-4);
    width: 100%;
    padding-bottom: 5px;
}
.modal-content-login-data .sc-right-box-2-el{
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 0px ;
}

.modal-content-download-mobile .modal-content-body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    color:var(--text-white);
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
}
.modal-content-small-2 .modal-content-sized-container-image {
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: contain;
    image-rendering: pixelated;
    min-height: 400px;
    top:0px

}
.modal-content-small-2::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
    transition: ease 0.3s;
    border-radius: var(--default-border-radius);
  
  }
.modal-content-small-2 .modal-content-sized-container-image img{
    width: 100%;
    max-width: 100%;
    height: 100%;
    min-height: 400px;
    border-radius: var(--default-border-radius);
    backdrop-filter: blur(5px);
   
}

.modal-content-small-2 .modal-content-sized-container-text-title{
    padding: 10px;
    background: #000000a1;
    position: relative;
    border-radius: var(--default-border-radius);
    text-align: center;
    z-index: 1;
}
.modal-content-small-2 .modal-content-sized-container-text{
    background: #000000b4;
    position: relative;
    margin-top: 60px;
    border-radius: var(--default-border-radius);
    text-align: center;
    padding: 10px;
    z-index: 1;
}

.modal-content-small-2  .modal-content-sized-container-buttons{
    position: relative;
    z-index: 2;
}
.modal-content-sized-container-buttons.enabled .modal-content-sized-container-buttons-button{
    background: var(--text-brand-2);
}