.announcements-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.announcement-item{
    min-width: 100%;
    width: auto;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: var(--text-white-2);
    fill:var(--text-white-2);
    border-radius: var(--default-border-radius);
    cursor: pointer;
    transition: ease 0.3s;
}
.announcement-item-image {
    width: 100%;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--default-border-radius);
}
.announcement-item-image img{
    width: 100%;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--default-border-radius);
}