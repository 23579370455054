.withdraw-container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    position: relative;
    margin: 0 auto;
    gap: 5px;
    width: calc(100% - 40px);
    background-color: var(--bg-color-3);
    padding: 10px;
    border-radius: var(--default-border-radius);

    margin: 20px;
  }
  
  .withdraw-container input:hover {
    border-bottom: 1px solid var(--text-gray-4);
  }
  .withdraw-container label {
    font-size: 12px;
  }
  .withdraw-container .input_field {
    padding: 0 0 0 5px;
  }
  
  .withdraw-crypto-para-label {
    width: 100%;
    font-size: 13px;
    margin-bottom: 5px;
    color: var(--text-gray-4);
    margin-left: 0px;
  }

  .withdraw-drop-btn {
    background: var(--bg-color-2);
    width: 100%;
    height: 35px;
    margin-bottom: 10px;
    padding: 3px 10px;
    border-radius: var(--default-border-radius);
    font-size: 12px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border: none;
  }
  .withdraw-drop-btn img {
    width: 15px;
    border-radius: var(--default-border-radius);

  }

  .withdraw-crypto-para-label {
    width: 100%;
    font-size: 13px;
    margin-bottom: 5px;
    color: var(--text-gray-4);
    margin-left: 0px;
  }
  .withdraw-btn,
  .withdraw-btn:focus {
    width: 100%;
    padding: 5px;
    border-radius: var(--default-border-radius);

    border: none;
    background: var(--text-white-2);
    color: #fff;
    font-size: 13px;
    height: 35px;
  }
  .withdraw-btn:hover {
    background: var(--text-white-2);
    color: #fff;
    font-size: 13px;
  }
  

.modal-cont-item-with {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    height: 35px;
    padding: 5px 10px;
    background: var(--bg-color-1);
    color: #fff;
    border-radius: var(--default-border-radius);

    margin-bottom: 20px;
    font-size: 12px;
  }
  .modal-cont-item-with div:nth-child(2) {
    color: #18a5b2;
    font-weight: bold;
  }
  
  .modal-cont-item-with-fee {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    padding: 5px 0px;
    color: var(--text-white-2);
    font-size: 12px;
  }
  .modal-cont-item-with-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 11px;
    margin-top: 10px;
    color: #18a5b2;
  }
  
  .modal-cont-item-with-price {
    background-color: var(--bg-color-1);
    padding: 5px 8px;
    border-radius: var(--default-border-radius);
    color: rgb(211, 211, 211);
  }
  
  .modal-cont-item-with-price p {
    padding: 0 3px;
    display: inline;
    margin-bottom: 0px;
  }
  .modal-cont-item-with-price p:nth-child(1) {
    border-right: 2px solid var(--text-gray-4);
  }
  .modal-cont-item-with-price p:nth-child(2) {
    color: #18a5b2;
  }
  .header-menu-box-withdraw {
    /* padding: 5px; */
    position: absolute;
    background: var(--bg-color-1);
    height: auto;
    top: 89px;
    left: 10px;
    overflow: auto;
    border-radius: 5px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .header-menu-box-withdraw-gc {
    /* padding: 5px; */
    position: absolute;
    background: var(--bg-color-1);
    height: auto;
    max-height: 200px;
    top: 89px;
    left: 10px;
    overflow: auto;
    border-radius:0 var(--default-border-radius) var(--default-border-radius) var(--default-border-radius);
    z-index: 30;
    display: flex;
    flex-direction: column;
    gap: 0px;
    color: #fff;
  }
  .header-menu-box-withdraw-gc .header-menu-box-item-gc {
    padding: 10px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: start;
    color: #fff !important;
    cursor: pointer;
    background-color: var(--bg-color-3);
    flex-direction: row;
    text-align: center;
    font-size: 13px;

    gap: 0px !important;
  }
  .header-menu-box-withdraw-gc .header-menu-box-item-gc img {
    height: 13px;
    border-radius: var(--default-border-radius);
    margin-right: 3px;
  }

  .cashout-option-header{
    display: flex;
    align-items: start;
    justify-content: space-between;
  }
  .cashout-option-title{
    font-size: 25px;
    color: var(--text-white);
  }
  .cashout-option-header-left{
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
    gap: 10px;
  }

  .withdraw-container .cashout-header-balance{
    height: auto;
    padding: 10px;
  }
  .header-balance-text-balance{
    font-size: 12px;
    color: var(--text-gray-4);
    margin-right: 5px;
  }

  @media screen and (max-width: 1050px) {
    .withdraw-container {
      width: calc( 100% -  40px);
    }
    .withdraw-container .cashout-header-balance{
      padding: 10px;
      margin-bottom: 20px;
    }
    .cashout-option-header{
      flex-direction: column;
      gap: 10px;
    }
    .cashout-option-header-left{
        width: calc( 100% -  40px);
    }
    .cashout-option-title{
      font-size: 20px;
    }
    .cashout-option-header-left{
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }
    .header-menu-box-withdraw-gc, .header-menu-box-withdraw{
        top: 48px;
        right: 10px;
        border-radius: var(--default-border-radius);
    }
  }
  .header-menu-box-withdraw .header-menu-box-item {
    padding: 10px;
    height: 30px;
  }
  .header-menu-box-withdraw .header-menu-box-item {
  color: #fff;
  padding: 0px 10px;
  height: 30px;
  min-width: 100px;
  font-size: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  flex-direction: row;
  gap: 5px;
  transition: color 0.3 ease;
}
