.wall-container-item{
    position: relative;
    height: 172px;
    width: auto;
    min-width: 130px;
    background-color: var(--bg-color-3);
    border-radius: var(--default-border-radius);
    padding: 10px;
    cursor: pointer;
}
.wall-container-item::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(50px);
    border-radius: var(--default-border-radius);
}

.wall-container-item-open{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /* background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%); */
    border-radius: var(--default-border-radius);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    display: flex;
    opacity: 0;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: var(--text-white-2);
    fill:var(--text-white-2);
    transition: ease 0.3s;
  
}
.wall-container-item-open svg{
    background-color: var(--text-brand);
    fill: var(--text-white);
    border-radius: 50%;
    width: 35px;
    height: 35px;
    padding: 8px;
}
.wall-container-item:hover .wall-container-item-open{
    z-index: 2;
    opacity: 1;
    transition: ease 0.3s;
}
.wall-container-image{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.wall-container-image img{
    z-index: 1;
    width: 100%;
    padding: 0px 10px;
    /* brigthness, make white */
}
.wall-container-item-info{
position: absolute;
bottom: 0;
width: calc(100% - 20px);
   backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0.233);
    border-radius:  var(--default-border-radius) var(--default-border-radius) 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
   margin: 0 auto;
   z-index: 2;
}

.wall-container-item-info-title{

    padding: 0 10px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--text-white-2);
    font-size: 10px;
    font-family: "Poppins", sans-serif;
    text-align: center;
}

.wall-container-promo{
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--text-brand-2);
    color: var(--text-white-2);
    padding: 5px 10px;
    border-radius: 0  var(--default-border-radius) 0 var(--default-border-radius);
    box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.75);
    font-size: 10px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    z-index: 2;
    transition: ease 0.3s;
    
}