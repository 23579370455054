.home-screen-container{
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: calc(100% - 40px);
    max-width: 100vw;
    font-family: 'Poppins', sans-serif;
    margin: 0 20px;
}

.show-case{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    margin:  50px 0;
}

  .cover-sc {
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background-size: contain;
    background-repeat: repeat;
    background-position-y: 50%;
    opacity: 2;
  }
  .show-case .logo-sc {
    height: 75px;
    margin-left: -20px;
    margin-bottom: 10px;
  }
  
  .show-case p {
    text-align: left;
    color: #ecf2fb;
    /* font-size: 16px; */
    margin-bottom: 0px;
  }
  .promo-sc {
    font-size: 14px !important;
    color: #8ca0b7 !important;
    text-align: center;
  }

  .gpf-sc {
    transition:  0.3s ease;

    font-size: 30px !important;
    color: var(--text-brand) !important;
    font-weight: bold;
    text-align: center;
  }
  .promo-sc-el {
    display: inline;
    font-family: 'Poppins', sans-serif;
    color: var(--text-brand) !important;
  }
  .show-case .btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .gpf-sc-el{
    display: inline;
    font-family: 'Poppins', sans-serif;
    color: var(--text-white) !important;
    margin-bottom: 0px;
  }
  .animate-text {
    font-size: 30px;
    margin-bottom: 0px;
    text-align: center;
    color: var(--text-white);
    font-family: 'Poppins', sans-serif;
    overflow: hidden;
  }
  .animate-text span {
    color: var(--text-highlight);
    font-weight: 700;
    display: inline-block;
    display: none;
  }
  
  .animate-text span.text-in {
    display: block;
    animation: textIn 0.3s ease;
  }
  .animate-text span.text-out {
    animation: textOut 0.3s ease;
  }
  @keyframes textIn {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }
  @keyframes textOut {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .card-body {
    z-index: 2;
    width: 100%;
    padding: 25px 30px;
    background-color: #1a2436;
    text-align: center;
  }
  .card-body p {
    color: var(--text-highlight) !important;
    margin-bottom: 0px;
    font-size: 12px;
  }
  .card-body .top {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 550;
    color: var(--bs-info) !important;
  }
  .card-body:nth-child(1) {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
  }
  .card-body:nth-child(3) {
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
  }
  .user-details-top p:nth-child(4) {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 11px;
    color: var(--text-primary);
  }
  
  .social-sc {
    text-align: center;
    font-size: 20px;
    margin-bottom: -30px;
    color: var(--text-highlight);
  }
  
  .social-sc div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .social-sc a {
    padding: 10px;
    font-size: 25px;
    color: var(--text-highlight);
  }
  .social-sc a:hover {
    color: var(--text-primary);
  }
  
  .partner-showcase{
    width: 120px !important;
    background: var(--bg-color-3);
    border-radius: var(--default-border-radius);
    padding: 10px;
  }
  .partner-showcase:hover{
    background: var(--bg-color-4);
    transition: ease 0.3s;
    cursor: pointer;
  }
  .partner-showcase img{
    width: 100%;
    filter: brightness(0) invert(1);
  }
.cashout-showcase{
    width: 100px !important;
    background: var(--bg-color-3);
    border-radius: var(--default-border-radius);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.cashout-showcase:hover{
  background: var(--bg-color-4);
  transition: ease 0.3s;
  cursor: pointer;
}
.cashout-showcase img{
    width: 50%;
    filter: brightness(0) invert(1);
  }
  .cashout-showcase p, .partner-showcase p{
    margin-bottom: 0px;
    font-size: 10px;
    color: var(--text-gray-4);
    margin-top: 5px;
    text-transform: capitalize;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .home-sc-logo-desktop{
    transition:  0.3s ease;
    height: 70px;
    margin-bottom: 20px;
  }
 
  .sc-right-box{
    transition:  0.3s ease;
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background: var(--bg-color-3);
    padding: 20px;
    border-radius: var(--default-border-radius);

  }

  .signup-main-button{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-size: 13px;
    background: #4d939a;
    font-weight: 500;
    color: var(--text-white);
    border-radius: var(--default-border-radius);
    cursor: pointer;
  }
    .signup-main-button:hover{
        background: #5fa9b0;
  transition: ease 0.3s;
    }
    .sc-right-box-3-el{
      
        font-size: 12px;
    }

    .sc-left{
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        max-width: 400px;
        gap: 10px;
      }


@media screen and (max-width:860px) {
    .home-sc-logo-desktop{
        transition:  0.3s ease;
        height: 50px;
    }
    .gpf-sc{
        transition:  0.3s ease;

        font-size: 20px !important;
    }
    .sc-right-box{
        transition:  0.3s ease;
        width: 300px;
    }
}
@media screen and (max-width:720px) {
    .show-case{
        flex-direction: column;
        gap: 20px;
    }
   .sc-left{
    align-items: center;

   }
   .show-case p{
    text-align: center;
   }
   .sc-right-box{
    width: 350px;
}
}
@media screen and (max-width:400px) {
   .sc-right-box{
    width: 100%;
}
}