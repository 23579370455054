.daily-rewards-container{
    display: flex;
    flex-direction: column;
}

.daily-rewards-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: calc(100% );
    gap: 20px;
} 

@media screen  and (max-width: 800px){
    .daily-rewards-header{
        flex-wrap: wrap;
    }
}

.daily-rewards-header-amount-container{
    background: var(--bg-color-3);
    width: 100%;
    border-radius: var(--default-border-radius);
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--text-gray-4);
}
.daily-rewards-top{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: start;
   gap: 20px;

}
.daily-rewards-top-info{
    background: var(--bg-color-3);
    border-radius: var(--default-border-radius);
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
}
.daily-rewards-top-info-title{
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    color: var(--text-white-2);
    margin-bottom: 5px;
}

.daily-rewards-top-info-description{
    font-size: 14px;
    text-align: center;
    color: var(--text-gray-4);
    width: 100%;
}


.daily-rewards-header-amount-container-title img{
    width: 12px;
    height: 12px;
    margin-bottom: 2px;
}
.daily-rewards-header-amount-container-title{
    font-size: 15px;
    color: var(--text-white);
}
.daily-rewards-header-amount-container-description{
    font-size: 12px;
    color: var(--text-gray-4);
}
.affiliate-levels{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}
.affiliate-level-container{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
.affiliate-level-position{
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: var(--bg-color-3);
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 30px;
   
}

.affiliate-level-data{
    background: var(--bg-color-3);
    border-radius: var(--default-border-radius);
    padding: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: start;
    width: 100%;
}
.affiliate-level-title{
    flex-wrap: wrap;
    font-size: 14px;
    color: var(--text-white-2);
    display: flex;
    align-items: start;
    gap: 10px;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--bg-color-1);
}

.affiliate-level-description{
    font-size: 12px;
    color: var(--text-gray-4);
}
.affiliate-level-title .affiliate-level-description-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    margin: 5px 0px;
}

.affiliate-level-title .affiliate-level-description-item-title{
    font-size: 12px;
    color: var(--text-gray-4);
}
.affiliate-level-description-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin: 5px 0px;
    margin-left: 10px;
}

.affiliate-level-description-item-title{
    width: 100%;
    color: var(--text-gray-4);
    font-size: 12px;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.affiliate-level-description-item-value{
    font-size: 12px;
    color: var(--text-gray-4);
    background: var(--bg-color-1);
    border-radius: var(--default-border-radius);
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 70px;
}
.daily-rewards-code{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 20px;
    background-color: var(--bg-color-3);
    border-radius: var(--default-border-radius);
    width: 100%;
    color: var(--text-gray-4);
    padding: 10px;
}
.daily-rewards-code .link{
   color: var(--text-white-2);
   margin-top: 5px;
}